.Index {
  display: flex;
  flex: 1;
  height: 90vh;
  flex-direction: column;
  align-content: center;
  margin: 30px 20px;
}
.Index a {
  color: #0000EE;
  cursor: pointer;
}
.Index .upcoming-course .content {
  height: 300px;
  width: 100%;
}
.Index .upcoming-course .content .MuiDataGrid-columnsContainer {
  color: #7f1083;
  font-size: 15px;
  font-weight: bold;
}
.Index .certifications {
  display: flex;
  flex-direction: column;
}
.Index .certifications .level-item {
  display: flex;
}
.Index .joined-courses .content {
  height: 300px;
  width: 100%;
}
.Index .joined-courses .content .MuiDataGrid-columnsContainer {
  color: #7f1083;
  font-size: 15px;
  font-weight: bold;
}
.Index .titles {
  display: flex;
}
.Index .titles .title {
  font-size: 32px;
  padding: 10px;
  cursor: pointer;
  background: #e0e0e0;
  color: #858585;
}
.Index .titles .title.selected {
  border-top: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  background: #ffffff;
  color: #000000;
}
