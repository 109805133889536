.planDetailContainer {
  margin: 0px 0 auto;
  background: #ffffff;
}
.planDetailContainer .controllBtn {
  display: flex;
}
.planDetailContainer .controllBtn button {
  border-radius: 8px 8px 0 0;
}
.planDetailContainer .controllBtn .deleteInstances {
  font-size: 1.7em;
  padding: 5px 10px;
  cursor: pointer;
  float: right;
}
.planDetailContainer .controllBtn .selected {
  background: #ffffff;
  outline: none;
}
.planDetailContainer .controllBtn .waitingApprove {
  color: #d9534f;
  padding: 0 5px;
}
.planDetailContainer .controllBtn .controll--Btns {
  display: flex;
  align-items: center;
  flex: 1;
}
.planDetailContainer .controllBtn .controll--Btns .submitLessonPlan {
  background: #871e8c;
  padding: 8px 15px;
  margin: 5px;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.planDetailContainer .controllBtn .controll--Btns .rejectHistoryArea {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
}
.planDetailContainer .controllBtn .controll--Btns .rejectHistoryArea .rejectTimes {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #d9534f;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  color: white;
}
.planDetailContainer .controllBtn .controll--Btns .rejectHistoryArea .rejectTimes .rejectDetail {
  position: relative;
}
.planDetailContainer .controllBtn .controll--Btns .rejectHistoryArea .rejectTimes .rejectDetail .rejectDetailContent {
  position: absolute;
  top: 15px;
  display: none;
  width: 500px;
  max-height: 400px;
  overflow: auto;
}
.planDetailContainer .controllBtn .controll--Btns .rejectHistoryArea .rejectTimes .rejectDetail .rejectDetailContent table {
  color: #000000;
  background: #ffffff;
  box-shadow: 0px 0px 10px 3px #8f8f8f;
}
.planDetailContainer .controllBtn .controll--Btns .rejectHistoryArea .rejectTimes .rejectDetail .rejectDetailContent table td {
  padding: 3px 5px;
}
.planDetailContainer .controllBtn .controll--Btns .rejectHistoryArea .rejectTimes:hover .rejectDetail .rejectDetailContent {
  display: block;
}
.planDetailContainer .stepController {
  background: #c7d3f7b8;
  width: 200px;
  height: 40px;
  border-radius: 3px;
  margin: 0px 2px;
  border-bottom: 0px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
}
.planDetailContainer .planDetailContent {
  margin: 15px 0 0 0;
  height: 75vh;
  overflow: auto;
}
.planDetailContainer .planDetailContent .sendAll {
  margin: 15px 0px;
  margin-left: 5px;
  width: 99%;
  height: 35px;
  background: #871e8c;
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
}
