.ApprovedMemberLessonPlans {
  height: 80vh;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content {
  height: 75vh;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content .MuiDataGrid-columnsContainer {
  background: #515bd4;
  color: #ffffff;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content .MuiDataGrid-cell .approveBtn {
  background: #337ab7;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  margin-left: 5px;
  cursor: pointer;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content .MuiDataGrid-cell .readBtn {
  background: #5cb85c;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  margin-left: 5px;
  cursor: pointer;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content .MuiDataGrid-cell .readBtn:hover {
  background: #4cae4c;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content .MuiDataGrid-cell .cancelBtn {
  background: #c9302c;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  margin-left: 5px;
  cursor: pointer;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content .MuiDataGrid-cell .cancelBtn:hover {
  background: #ac2925;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content table {
  width: 100%;
  overflow: auto;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content table thead {
  background: #515bd4;
  color: #ffffff;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content table td {
  padding: 10px;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content table tbody .loading {
  text-align: center;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content table tbody tr:hover {
  background: #e2e0e0;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content table tbody td {
  border-bottom: 1px solid #515bd4;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content table tbody td .approveBtn {
  background: #337ab7;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  margin-left: 5px;
  cursor: pointer;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content table tbody td .readBtn {
  background: #5cb85c;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  margin-left: 5px;
  cursor: pointer;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content table tbody td .readBtn:hover {
  background: #4cae4c;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content table tbody td .cancelBtn {
  background: #c9302c;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  margin-left: 5px;
  cursor: pointer;
}
.ApprovedMemberLessonPlans .MemberLessonPlans__content table tbody td .cancelBtn:hover {
  background: #ac2925;
}
