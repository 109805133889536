.MemberCertificationDetail a {
  color: #0000EE;
  cursor: pointer;
}
.MemberCertificationDetail .title {
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}
.MemberCertificationDetail .MemberCertificationDetail__content {
  height: 90vh;
  overflow: auto;
}
.MemberCertificationDetail .MemberCertificationDetail__content .title {
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 20px;
}
.MemberCertificationDetail .MemberCertificationDetail__content .teacher-lessonplans {
  height: 220px;
}
.MemberCertificationDetail .MemberCertificationDetail__content .teacher-courses {
  height: 400px;
}
.MemberCertificationDetail .MemberCertificationDetail__content .warning {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  background: #fff3cd;
  color: #856404;
}
.MemberCertificationDetail .MemberCertificationDetail__content .MuiDataGrid-columnsContainer {
  background: #515bd4;
  color: #ffffff;
}
.MemberCertificationDetail .MemberCertificationDetail__content .certification-form-controller {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}
.MemberCertificationDetail .MemberCertificationDetail__content .certification-form-controller button {
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  font-size: 15px;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
