.Admin {
	a {
		text-decoration: none;
	}
}


.ReactModalPortal,
.MuiDialogTitle-root {
	.modalControllArea {
		display       : flex;
		align-items   : center;
		flex-direction: row-reverse;

		svg {
			cursor: pointer;
		}

		>.title {
			flex       : 1;
			font-size  : 25px;
			font-weight: bold;
		}
	}
}

.Admin__row {
	display: flex;
	padding: 5px 0;

	input {
		padding      : 3px;
		border-radius: 5px;
		font-size    : 15px;
		width        : 90%;
	}

	textarea {
		padding      : 3px;
		border-radius: 5px;
		font-size    : 15px;
		width        : 90%;
		border       : 2px solid black;
	}

	.item_required::before {
		content: "*";
		color  : #fc0202;
	}

	.Admin__row__item {
		flex: 1;
	}

	.Admin__row__item2 {
		flex: 2;
	}

	.Admin__row__item3 {
		flex: 3;
	}

	.Admin__row__item4 {
		flex: 4;
	}
}

.Admin__row.underline {
	border-bottom: 1px solid #7f1083;
}