.LessonPlanResources {
	flex: 1;
	display: flex;
    flex-direction: column;
	height: 90vh;
	
	.LessonPlanResources__searchCondition {
		.LessonPlanResources__searchCondition__title {
			width     : 150px;
			text-align: center;
		}

		.academicYears,
		.lessonPlanGrades,
		.lessonTargetReigons,
		.lessonTargetKernels,
		.lessonTargetPoints,
		.lessonTargetContents,
		.lessonTargetActivities {
			select {
				font-size    : 14px;
				border-radius: 5px;
				padding      : 3px;
			}

		}

		.selectedBtn {
			font-size    : 16px;
			color        : #ffffff;
			padding      : 5px 10px;
			border       : 0px;
			background   : #38b3bf;
			cursor       : pointer;
			border-radius: 5px;
			margin       : 0 10px;
		}

		.selectedBtn:hover {
			background: #32a0aa;
		}

		.selectedItem {
			font-size    : 16px;
			color        : #ffffff;
			padding      : 5px 20px;
			border       : 0px;
			background   : #007db4;
			cursor       : pointer;
			border-radius: 5px;
			margin       : 5px 3px;
		}

		.selectedItem:hover {
			background: #016a97;
		}

		.LessonPlanResources__searchCondition__controlArea {
			button {
				background   : #871e8c;
				font-size    : 16px;
				color        : #ffffff;
				padding      : 5px 20px;
				border       : 0px;
				cursor       : pointer;
				border-radius: 5px;
				margin       : 5px auto;
			}

			button:hover {
				background: #651769;
			}
		}
	}

	.LessonPlanResources__result {
		height: 100%;
		overflow: auto;
		table {
			width: 100%;

			thead {
				background: #515bd4;
				color     : #ffffff;
			}

			td {
				padding: 10px;
			}

			tbody {
				.loading {
					text-align: center;
				}

				tr:hover {
					background: #e2e0e0;
				}

				td {
					border-bottom: 1px solid #515bd4;

					svg {
						color: #000000;
					}

					.LessonPlanResources__result__item__readBtn {
						padding      : 5px 15px;
						color        : #383d41;
						background   : #e2e3e5;
						border-radius: 5px;
						border       : 0px;
						cursor       : pointer;
						margin       : 0 5px;
					}

					.LessonPlanResources__result__item__readBtn:hover {
						background: #383d41;
						color     : #e2e3e5;
					}
				}
			}
		}
	}
}

.home{
	.LessonPlanResources{
		margin: 30px 20px;
		.title{
			font-size: 32px;
			margin: 32px 0;
		}
		.warn_word{
			color:red
		}
	}
}