.share-detail {
    display: flex;
    flex: 1;
    height: 70vh;
    flex-direction: column;
    align-content: center;
    margin: 30px 20px;

    .banner-title {
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 15px;
    }

    .row {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        width: 100%;

        .title {
            font-weight: bold;
            width: 100px;
        }

        .content {
            display: flex;
            flex-direction: column;
            flex: 1;

            .uploaded-image {
                width: 400px;
                height: 300px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                border: 1px solid gray;
                border-radius: 5px;
            }
        }

        &.buttons {
            justify-content: center;
            padding-top: 10px;

            button {
                background: #515bd4;
                padding: 5px 10px;
                margin: 0 10px;
                border: 0;
                border-radius: 5px;
                color: #ffffff;
                cursor: pointer;

                &:hover {
                    background: #444dad;
                }
            }
        }
    }
}