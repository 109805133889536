.MemberApproveCertification{
	.MemberManagement__searchArea{
		padding: 5px 0;
		input {
			padding: 3px;
			border-radius: 5px;
			font-size: 15px;
			margin: 0 10px;
			width: 400px;
		}

		button{
			background: #515bd4;
			padding: 5px 10px;
			border: 0;
			border-radius: 5px;
			color: #ffffff;
			cursor: pointer;
		}
	}

	.teacher-lessonplans{
		height: 300px;
	}

	.teacher-courses{
		height: 300px;
	}

    .MemberApproveCertification__content {
		height: 90vh;
		overflow: auto;

		.title{
			padding-bottom: 15px;
			font-weight: bold;
			font-size: 20px;
		}

		.warning{
			padding: 10px;
			margin: 10px 0;
			border-radius: 5px;
			background: #fff3cd;
			color: #856404;
		}
				
		.MuiDataGrid-columnsContainer {
            background: #515bd4;
            color     : #ffffff;
		}

		.certification-form-controller{
			display: flex;
			justify-content: center;
			padding-top: 15px;

			button{
				background: #515bd4;
				padding: 5px 10px;
				border: 0;
				font-size:15px;
				border-radius: 5px;
				color: #ffffff;
				cursor: pointer;
			}
		}

        .MuiDataGrid-cell {
        }
	}
	
	.certification-form{
		input{
			margin-left: 20px;
		}
	}
}
