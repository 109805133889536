.share-detail {
  display: flex;
  flex: 1;
  height: 70vh;
  flex-direction: column;
  align-content: center;
  margin: 30px 20px;
}
.share-detail .banner-title {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 15px;
}
.share-detail .row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}
.share-detail .row .title {
  font-weight: bold;
  width: 100px;
}
.share-detail .row .content {
  display: flex;
  flex: 1;
}
.share-detail .row .content.ckeditor-content {
  min-height: 400px;
  max-height: 400px;
  overflow: auto;
  border: 1px solid #616161;
  display: block;
  padding: 0 15px;
}
.share-detail .row .content input {
  flex: 1;
  border-radius: 5px;
  padding: 5px;
  font-size: 15px;
}
.share-detail .row .content textarea {
  flex: 1;
  border-radius: 5px;
  padding: 5px;
  font-size: 15px;
  height: 500px;
  border: 2px solid #000000;
}
.share-detail .row.buttons {
  justify-content: center;
  padding-top: 10px;
}
.share-detail .row.buttons button {
  background: #515bd4;
  padding: 5px 10px;
  margin: 0 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.share-detail .row.buttons button:hover {
  background: #444dad;
}
.share-detail .row.buttons .verify-area {
  display: flex;
  flex-direction: column;
}
.share-detail .row.buttons .verify-area .row {
  margin-top: 10px;
}
.share-detail .row.buttons .verify-area .row .delete-btn {
  background: #dc3545;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.share-detail .row.buttons .verify-area .row .delete-btn:hover {
  background: #c82333;
}
.share-detail .row.buttons .verify-area .row .warn-btn {
  background: #ffc107;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.share-detail .row.buttons .verify-area .row .warn-btn:hover {
  background: #e0a800;
}
.share-detail .row.buttons .verify-area .row input {
  flex: 1;
  border-radius: 5px;
  padding: 5px;
  font-size: 15px;
}
