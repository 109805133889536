.container {
  width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
}
.stepStatusArea {
  display: inline-block;
}
.stepStatusArea .submitApproveLessonPlanBtn {
  background: #871e8c;
  padding: 8px 15px;
  margin: 5px 10px;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.stepStatusArea .submitApproveLessonPlanBtn:hover {
  background: #67176b;
}
.stepStatusArea .approvedLessonPlanStatus {
  color: #449d44;
}
.global_hide {
  display: none;
}
