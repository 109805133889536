.TeachersManagement .TeachersManagement__title {
  color: #232ca0;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 18px;
}
.TeachersManagement .TeachersManagement__control_panel {
  align-items: center;
}
.TeachersManagement .TeachersManagement__control_panel svg {
  font-size: 20px;
  margin-right: 10px;
}
.TeachersManagement .TeachersManagement__control_panel .Admin__row {
  width: 200px;
}
.TeachersManagement .TeachersManagement__content {
  display: flex;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__unselected_content {
  width: 200px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 550px;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__unselected_content .teacher-item {
  border-radius: 10px;
  padding: 5px 10px;
  margin: 3px;
  border: 1px solid #838acf;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  word-break: break-all;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__unselected_content .teacher-item:hover {
  background: #838acf;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__unselected_content .selected {
  background: #2d3485;
  color: #ffffff;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 550px;
  overflow-y: auto;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item {
  border-radius: 10px;
  padding: 5px 10px;
  margin: 3px;
  border: 1px solid #616ace;
  background: #616ace;
  color: #ffffff;
  display: flex;
  flex-direction: column;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .left-area {
  display: flex;
  align-items: center;
  word-break: break-all;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .left-area .remove-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .left-area svg,
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .left-area i {
  font-size: 20px !important;
  margin: 5px;
  cursor: pointer;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .right-area {
  display: flex;
  align-items: center;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .right-area div {
  display: flex;
  align-items: center;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .right-area div .option {
  cursor: pointer;
  padding: 0 10px;
  color: #999dc9;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .right-area div .option:hover {
  font-size: 20px;
  color: #ffffff;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .right-area div .selected {
  font-size: 20px;
  font-weight: bold;
  color: #92ff2c;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .admin-info {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  background: #8b90c5;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 3px;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .admin-info .confirm-btn {
  width: 50px;
  padding: 0 10px;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .admin-info .admin-full-name {
  flex: 1;
  display: flex;
  align-items: center;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .admin-info .admin-full-name .title {
  margin-left: 10px;
  width: 50px;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .admin-info .admin-id {
  flex: 1.5;
  display: flex;
  align-items: center;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .admin-info .admin-id .title {
  margin-left: 10px;
  width: 140px;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .admin-info .title {
  width: 100px;
  font-weight: bold;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .delete-certification {
  width: 60px;
  background: #64656d;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  margin-right: 15px;
  color: #ffffff;
  cursor: pointer;
}
.TeachersManagement .TeachersManagement__content .TeachersManagement__selected_content .teacher-item .row .delete-certification:hover {
  background: #cbccd8;
}
.TeachersManagement .TeachersManagement__save_area {
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.TeachersManagement .TeachersManagement__save_area .saveBtn {
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.TeachersManagement .TeachersManagement__save_area .saveBtn:hover {
  background: #2d3485;
}
.TeachersManagement .TeachersManagement__save_area .error-msg {
  color: #f82f2f;
}
.TeachersManagement .TeachersManagement__save_area .success-msg {
  color: #19be0a;
}
