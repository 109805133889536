.planTargetTable {
    width: 100%;
}

.planBasicTarget {

    margin: 5px 10px;

    .planTargetTable {
        width          : 100%;
        border-collapse: collapse;

        div{
            display: flex;
            padding: 3px;
        }

        .basicLable {
            text-align: center;
            width     : 200px;
        }

        select {
            flex: 1;
            margin: 0 5px;
            padding  : 5px 2px;
            width    : 150px;
            font-size: 15px;
        }

        button{
            cursor:pointer;
        }

        
        .buttonBox{
            display: block;
        }

        .deleteEleBtn {
            background   : #871e8c;
            color        : #ffffff;
            padding      : 5px 10px;
            margin       : 0px 5px;
            border-radius: 5px;
            text-align   : center;
            cursor       : pointer;

            svg {
                margin: 0 4px 0 0;
            }
        }
    }
}