@color-pu: #871e8c;

.signInbarBanner {
    background     : @color-pu;
    display        : flex;
    flex-direction : column;
    justify-content: center;

    .signInForm {
        display       : flex;
        flex-direction: column;
        margin-bottom : 20px;

        .signInForm__title {
            color          : #ffffff;
            font-size      : 40px;
            background     : #68056B;
            font-weight    : bold;
            padding: 10px 0;
            text-align: center;
        }

        .signInForm__content {
            margin: 0 auto;

            .forget-password-btn{
                text-align: center;
                text-decoration: none;

                &:hover{
                    background: #68056B;
                }
            }

            .inputtext-email{
                width: 100%;
            }
        }

        .inputBox {
            padding-top    : 20px;
            display        : flex;
            justify-content: space-between;
            width          : 330px;


            label {
                font-family: 'PingFangTC-Regular', 'PingFang TC';
                font-weight: 400;
                font-style : normal;
                font-size  : 20px;
                color      : #FFFFFF;
            }
        }

        .btnBox {
            display        : flex;
            justify-content: center;
            width          : 330px;

            align-self: center;
            padding   : 2px 2px 2px 2px;
            box-sizing: border-box;

            padding-top: 20px;


            .btn {
                font-size    : 14px;
                color        : #ffffff;
                padding      : 10px 20px;
                border       : 1px solid #ffffff;
                background   : #871e8c;
                cursor       : pointer;
                border-radius: 5px;
                flex:1;

            }

            button:hover {
                background: #68056B;
            }
        }
    }
}