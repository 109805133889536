.step2Body {
    margin: 5px 10px;

    .step2Content{
        height: 60vh;
        overflow: auto;
    }
}

.addNewStepBtn {
    font-size    : 16px;
    color        : #ffffff;
    padding      : 5px 10px;
    border       : 0px;
    background   : #007db4;
    cursor       : pointer;
    border-radius: 5px;

}

.step2BasicTitle {
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0;
}

.sendLessonPlanClasses {
    margin     : 15px 0px;
    margin-left: 5px;
    width      : 99%;
    height     : 35px;
    background : #871e8c;
    color      : #ffffff;
    font-size  : 15px;
    font-weight: 400;
    cursor     : pointer;
}