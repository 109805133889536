.Signature .Signature__controlArea {
  display: flex;
  padding: 5px 10px;
}
.Signature .Signature__controlArea button {
  background: #515bd4;
  padding: 5px 10px;
  margin: 0 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.Signature .Signature__content {
  padding: 5px 10px;
  height: 90vh;
}
.Signature .Signature__content.hide {
  display: none;
}
.Signature .Signature__content button {
  margin-right: 5px;
}
.Signature .Signature__content .signature-image {
  width: 250px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.Signature .Signature__content .delete-btn {
  background: #dc3545;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.Signature .Signature__content .delete-btn:hover {
  background: #c82333;
}
