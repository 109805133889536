.planslist {
  flex  : 1;
  height: 90vh;
  margin: 30px 20px;

  .plansBanner {

    .listHeader {
      font-size: 32px;
      color    : #000000;
    }


    .newPlanBtn {
      font-size    : 16px;
      color        : #ffffff;
      padding      : 10px 20px;
      border       : 0px;
      background   : #029b5a;
      cursor       : pointer;
      border-radius: 5px;
    }

    .newPlanBtn:hover {
      background: #025c37;
    }
  }

  .planslist__content {
    height: 100%;
    overflow: auto;
    table {
      border-collapse: collapse;
      border-spacing : 0;
      width          : 100%;
      border         : 1px solid #ddd;
      margin-top     : 10px;

      thead {
        th {
          padding: 8px 16px;
        }
      }

      tbody {

        tr:hover {
          background: #ddd;
        }

        td {
          text-align: center;
          padding   : 5px;

          .keywordArea {
            display: flex;

            .count {
              padding      : 0 5px;
              margin-right : 5px;
              border-radius: 5px;
              color        : #ffffff;
              background   : #029b5a;
            }

            .keywords {
              flex       : 1;
              text-align : left;
              width      : 200px;
              overflow   : hidden;
              white-space: nowrap;

              div {
                display: inline;
              }
            }
          }
        }

        th:first-child,
        td:first-child {
          text-align: left;
        }

        .editor {
          font-size    : 16px;
          color        : #ffffff;
          padding      : 5px 10px;
          border       : 0px;
          background   : #027db4;
          cursor       : pointer;
          border-radius: 5px;
          margin-right : 5px;
        }

        .editor:hover {
          background: #003249;
        }

        .deletebtn {
          font-size    : 16px;
          color        : #ffffff;
          padding      : 5px 10px;
          border       : 0px;
          background   : #a30014;
          cursor       : pointer;
          border-radius: 5px;
        }

        .deletebtn:hover {
          background: #75000e;
        }
      }


    }
  }

}