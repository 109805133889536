.Course .Course__title {
  color: #232ca0;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 18px;
}
.Course .course-content {
  height: 65vh;
  overflow: auto;
}
.Course .controlArea {
  flex-direction: row-reverse;
  justify-content: center;
  padding-top: 10px 0;
}
.Course .saveBtn,
.Course .manageTeachersBtn {
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  font-size: 15px;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.Course textarea {
  height: 150px;
}
.Course .optionItem {
  cursor: pointer;
}
.Course .optionItem input {
  width: auto;
  padding: 5px;
}
.Course .signature-image {
  width: 250px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
