.achievementsBody {
    margin: 5px 10px;

    .StudentPlanTitle {
        font-size  : 20px;
        font-weight: bold;
        padding    : 10px 0;
    }

    .editor {
        font-size    : 16px;
        color        : #ffffff;
        padding      : 5px 10px;
        border       : 0px;
        background   : #007db4;
        cursor       : pointer;
        border-radius: 5px;
    }

    .nonEditor{
        font-size    : 16px;
        color        : #ffffff;
        padding      : 5px 10px;
        border       : 0px;
        background   : gray;
        border-radius: 5px;
    }

    .firstCol {
        width: 100px;
    }

    .StudentPlanContent {
        border-radius: 10px;
        text-align   : center;

        tr {
            td {
                width     : 200px;
                padding   : 10px 0;
                text-align: center;
            }

        }
    }
}

.addStudentBtn {
    font-size    : 16px;
    color        : #ffffff;
    padding      : 5px 10px;
    border       : 0px;
    background   : #007db4;
    cursor       : pointer;
    border-radius: 5px;
}

.studentBtn{
    font-size    : 16px;
    color        : #ffffff;
    padding      : 5px 10px;
    border       : 0px;
    background   : Gray;
    border-radius: 5px;
}


.sendAchievements {
    margin     : 15px 0px;
    margin-left: 5px;
    width      : 99%;
    height     : 35px;
    background : #871e8c;
    color      : #ffffff;
    font-size  : 15px;
    font-weight: 400;
    cursor     : pointer;
}