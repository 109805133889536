.resourceBody {
  margin: 5px 10px;
}
.resourceTile {
  font-size: 1.17em;
}
.resourceTable {
  width: 100%;
  border-collapse: collapse;
}
.resourceTable .fileUpBox {
  display: flex;
}
.resourceTable .fileUpBox > div {
  flex: 1;
  margin: 5px;
  border-radius: 5px;
  background: #c4c4c4;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
}
.resourceTable .fileUpBox > div label > div {
  color: #858585;
}
.resourceTable .fileUpBox > div .uploadedFile {
  position: relative;
  color: #000000;
  cursor: pointer;
}
.resourceTable .fileUpBox > div .uploadedFile > div:first-child {
  position: absolute;
  padding: 0 15px;
  font-size: 20px;
}
.resourceTable .fileUpBox > div .uploadedFile svg {
  cursor: pointer;
}
.resourceTable .fileUpBox > div .uploadedFile .uploadFileName {
  position: absolute;
  height: 40px;
  left: 45px;
  width: 90%;
  overflow: hidden;
  text-align: center;
}
.resourceTable .title {
  width: 200px;
  text-align: center;
  padding: 10px 0;
}
.resourceTable .upFile {
  background: grey;
}
.resourceTable .file {
  display: none;
}
.resourceTable label {
  cursor: pointer;
  background: gray;
  text-align: center;
}
