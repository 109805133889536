.RecommendResources{

	.RecommendResources__controlArea { 
		padding: 5px 0;

		button{
			background: #515bd4;
			padding: 7px 20px;
			border: 0;
			font-size: 20px;
			border-radius: 5px;
			color: #ffffff;
			cursor: pointer;
			margin: 0 5px;
		}
	}

    .RecommendResources__content {    	
    	table{
    		width: 100%;
    		thead{
    			background: #515bd4;
    			color: #ffffff;		
    		}

    		td{
    			padding: 10px;
    		}

    		tbody{    			

    			tr:hover{
	    			background: #e2e0e0;	    			
	    		}

		    	td{	
		    		border-bottom: 1px solid #515bd4;

					.RecommendResources__content__deleteBtn{
						padding: 5px 15px;
						color: #721c24;
						background: #f8d7da; 
						border-radius: 5px;
						border: 0px;
						cursor: pointer;
						margin: 0 5px;
					}

					.RecommendResources__content__deleteBtn:hover{	    			
						background: #721c24; 	
						color: #f8d7da;	
					}

					.RecommendResources__content__editBtn{
						padding: 5px 15px;
						color: #383d41;
						background: #e2e3e5; 
						border-radius: 5px;
						border: 0px;
						cursor: pointer;
						margin: 0 5px;
					}

					.RecommendResources__content__editBtn:hover{	    			
						background: #383d41; 	
						color: #e2e3e5;	
					}
		    	}
    		}    		
    	}
    }
}
