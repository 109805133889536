.planTargetTable {
  width: 100%;
}
.planBasicTarget {
  margin: 5px 10px;
}
.planBasicTarget .planTargetTable {
  width: 100%;
  border-collapse: collapse;
}
.planBasicTarget .planTargetTable div {
  display: flex;
  padding: 3px;
}
.planBasicTarget .planTargetTable .basicLable {
  text-align: center;
  width: 200px;
}
.planBasicTarget .planTargetTable select {
  flex: 1;
  margin: 0 5px;
  padding: 5px 2px;
  width: 150px;
  font-size: 15px;
}
.planBasicTarget .planTargetTable button {
  cursor: pointer;
}
.planBasicTarget .planTargetTable .buttonBox {
  display: block;
}
.planBasicTarget .planTargetTable .deleteEleBtn {
  background: #871e8c;
  color: #ffffff;
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}
.planBasicTarget .planTargetTable .deleteEleBtn svg {
  margin: 0 4px 0 0;
}
