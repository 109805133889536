.shareContent {
    display       : flex;
    flex          : 1;
    height        : 90vh;
    flex-direction: column;
    align-content : center;
    margin: 30px 20px;

    .shareContent_title {
        font-size: 32px;
        margin   : 32px 0;
    }

    .shareContent__controlArea {
        display: flex;

        .uploadLabel {
            padding      : 5px 10px;
            border-radius: 5px;
            background   : #00a7f0;
            margin       : 0 5px;
            cursor       : pointer;
            color        : #ffffff;
            font-size    : 15px;

            input {
                display: none;
            }
        }

        button {
            padding      : 5px 10px;
            font-size    : 15px;
            border-radius: 5px;
            border       : 0px;
            margin       : 0 5px;
            background   : #00a7f0;
            cursor       : pointer;
            color        : #ffffff;

            &:hover{
                background   : #0198d8;
            }
        }
    }

    .shareContent_section{
        display: flex;
        flex-direction: column;
        flex: 1;

        &.hide{
            display: none;
        }
        .title{
            padding-top: 20px;
            font-weight: 800px;
            font-size: 25px;
        }

        .MuiDataGrid-columnsContainer {
            background: #515bd4;
            color     : #ffffff;
        }

        .content{
            flex: 1;

            button{
                margin-right: 5px;
            }
            .delete-btn {
                background: #dc3545;
                padding: 5px 10px;
                border: 0;
                border-radius: 5px;
                color: #ffffff;
                cursor: pointer;
    
                &:hover {
                    background: #c82333;
                }
            }

            .update-btn {
                background: #007bff;
                padding: 5px 10px;
                border: 0;
                border-radius: 5px;
                color: #ffffff;
                cursor: pointer;
    
                &:hover {
                    background: #0069d9;
                }
            }

            .info-btn {
                background: #17a2b8;
                padding: 5px 10px;
                border: 0;
                border-radius: 5px;
                color: #ffffff;
                cursor: pointer;
    
                &:hover {
                    background: #138496;
                }
            }

            .warn-btn{
                background: #ffc107;
                padding: 5px 10px;
                border: 0;
                border-radius: 5px;
                color: #ffffff;
                cursor: pointer;
    
                &:hover {
                    background: #e0a800;
                }
            }

            .cancel-btn{
                background: #6c757d;
                padding: 5px 10px;
                border: 0;
                border-radius: 5px;
                color: #ffffff;
                cursor: pointer;
    
                &:hover {
                    background: #5a6268;
                }
            }

            .success-btn{
                background: #28a745;
                padding: 5px 10px;
                border: 0;
                border-radius: 5px;
                color: #ffffff;
                cursor: pointer;
    
                &:hover {
                    background: #218838;
                }
            }

            .control-area{
                display: flex;
                align-items: center;
                .reject-block{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 2px dotted #17a2b8;
                    border-radius: 50%;
                    background: #ffffff;
                    width: 30px;
                    height: 30px;
                    color: #17a2b8;
                }
            }
        }
    }

    // .showList {
    //     display  : flex;
    //     height   : 100%;
    //     flex-wrap: wrap;
    //     overflow : auto;
    //     margin   : 5px 0px;

    //     .fileBox {
    //         height       : 120px;
    //         width        : 120px;
    //         margin       : 10px;
    //         border-radius: 5px;
    //         padding      : 10px;
    //         border       : 1px solid #a3dcf5;
    //         background   : #a3dcf5;

    //         .fileBox__file__removeBtn {
    //             display       : flex;
    //             flex-direction: row-reverse;

    //             svg {
    //                 margin: 2px;
    //                 cursor: pointer;
    //             }
    //         }

    //     }

    //     .fileBox:hover {
    //         background: #93d9f7;
    //     }
    // }

    // .controlBtnBox {
    //     display       : flex;
    //     flex-direction: round;

    //     button {
    //         width        : 95%;
    //         border-radius: 4px;
    //         margin       : 1px 4px;
    //     }
    // }
}