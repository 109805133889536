.successDiv {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 80%;
}
.successDiv h1 {
  align-self: center;
}
.successDiv .content {
  width: 80%;
  margin: 10px 80px;
}
.successDiv .contact {
  width: 80%;
  margin: 10px 80px;
  border: 1px solid;
}
.successDiv .contact p {
  margin: 10px 10px;
}
