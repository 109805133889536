.Navitaion {
  background-color: #7f1083;
  display: flex;
  justify-content: center;
  padding: 0 50px;
}
.Navitaion .navbar a {
  font-size: 16px;
  color: white;
  text-align: center;
  padding: 14px 16px;
}
.Navitaion .dropdown {
  cursor: pointer;
}
.Navitaion .dropdown .dropbtn {
  cursor: pointer;
  font-size: 16px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 40px;
}
.Navitaion .dropdown .dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.Navitaion .dropdown .dropdown-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.Navitaion .dropdown .dropdown-content a:hover {
  background-color: #ddd;
}
.Navitaion .dropdown:hover .dropbtn {
  background-color: red;
}
.Navitaion .contactusLink {
  text-decoration: none;
  color: #f9f9f9;
}
.Navitaion .dropdown:hover .dropdown-content {
  display: block;
}
