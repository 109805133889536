.banner{
	display:flex;
	justify-content: center;
	padding: 30px 30px;
	background: #630c67;
	
	.image{
		width:50%;
		height: 250px;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}

	.title{
		width:50%;
		height: 250px;
		color: #ffffff;
		font-size: 40px;
		font-weight: bold;
		display:flex;
		justify-content:center;
		align-items: center;
	}
}
