.instancesTable {
    width          : 100%;
    border-collapse: collapse;

    >tbody>tr>td:nth-child(2) {
        padding: 10px 10px;

        .step {
            padding  : 5px 2px;
            width    : 150px;
            font-size: 15px;
        }

        .sourceUrl {
            width        : 400px;
            border-radius: 5px;
            padding      : 5px;
            font-size    : 15px;
        }

        .addSourceUrl {
            padding      : 5px 10px;
            margin       : 0px 5px;
            border-radius: 5px;
            cursor       : pointer;
            background   : #d4d3d3;
        }

        .resourceList {
            .resourceItem {
                display      : flex;
                padding      : 3px 5px;
                border-radius: 5px;
                margin       : 3px 0;
                background   : #dddddd;
                cursor       : pointer;
                font-size    : 18px;

                .removeBtn {
                    color : #990101;
                    margin: 0 5px;
                }
            }

            .resourceItem:hover {
                background: #b4b4b4;
            }
        }
    }

    .tableHeader {
        background     : #dddddd;
        display        : flex;
        justify-content: space-between;

        >svg {
            font-size: 20px;
            margin   : 5px;
            cursor   : pointer;
        }

        .deleteInstances {
            cursor: pointer;

            svg {
                font-size: 20px;
                margin   : 5px 10px;
            }
        }
    }

    td.warning {
        background: #ffab84;

        .notice {
            color: red;
        }
    }

    .instancesTitle {
        padding    : 10px 10px;
        width      : 150px;
        text-align : right;
        font-weight: bold;

        .notice {
            color: red;
        }

        .teacherAbilityManageBtn {
            background   : #8f6791;
            padding      : 4px 15px;
            margin       : 10px 0;
            border-radius: 5px;
            color        : #ffffff;
            cursor       : pointer;
        }
    }

    .instancesTitle.warning {
        background: #ffab84;
    }

    .removeBtn {
        cursor: pointer;
    }

    .student {
        margin         : 0 auto;
        width          : 80%;
        border-style   : solid;
        border-collapse: collapse;

        tr {
            td {
                width     : 200px;
                padding   : 10px 0;
                text-align: center;
            }

        }

        .editorBtn {
            font-size    : 16px;
            color        : #ffffff;
            padding      : 5px 10px;
            border       : 0px;
            background   : #A246A5;
            cursor       : pointer;
            border-radius: 5px;
        }
    }

    .resourceArea {
        .carete-resource-btn {
            font-size    : 16px;
            color        : #ffffff;
            padding      : 5px 10px;
            border       : 0px;
            background   : #38b3bf;
            cursor       : pointer;
            border-radius: 5px;
            margin       : 5px 3px;

            &:hover {
                background: #063f44;
            }
        }

        .RecommendResources__content__editBtn,
        .RecommendResources__content__deleteBtn {
            font-size    : 16px;
            color        : #ffffff;
            padding      : 5px 10px;
            border       : 0px;
            background   : #A246A5;
            cursor       : pointer;
            border-radius: 5px;
            margin       : 0 3px;

            &:hover {
                background: #750d47;
            }
        }

        .resource-item {
            display      : flex;
            align-items: center;
            padding      : 3px 3px;
            border-bottom: 2px solid #b4b4b4;

            &:hover {
                background: #b4b4b4;
            }
        }
    }

    .demoImg {
        width : 120px;
        height: 60px;
    }

    .photoList,
    .videoList {
        display: flex;

        .photoItem,
        .videoItem {
            width         : 33%;
            margin        : 5px;
            display       : flex;
            align-items   : center;
            flex-direction: column;

            .videoItem__top,
            .photoItem__top {
                display: flex;
                width  : 100%;
            }

            .photo__btn-remove,
            .video__btn-remove {
                cursor      : pointer;
                color       : #990101;
                padding-left: 10px;
                font-size   : 20px;
            }

            .photo__btn-remove:hover,
            .video__btn-remove:hover {
                background: #dddddd;
            }

            .photo,
            .video {
                width                 : 100%;
                height                : 200px;
                border                : 1px solid #dddddd;
                border-radius         : 5px;
                // width              : 150px;
                // height             : 150px;
                background-repeat     : no-repeat;
                background-size       : contain;
                background-position   : center;
                margin                : 0 10px;
            }

            .photo__content,
            .video__content {
                flex          : 1;
                display       : flex;
                flex-direction: column;
                margin        : 0 10px;

                .photo__content__title,
                .video__content__title {
                    font-size  : 20px;
                    font-weight: bold;
                }

                .photo__content__desc,
                .video__content__desc {
                    word-break: break-all;
                }
            }
        }
    }

    .uploadLabel {
        cursor: pointer;
    }

    .uploadInput {
        display: none;
    }
}

.addStudent,
.addFile {
    font-size    : 16px;
    color        : #ffffff;
    padding      : 5px 10px;
    border       : 0px;
    background   : #38b3bf;
    cursor       : pointer;
    border-radius: 5px;
    margin       : 3px 0;

    &:hover {
        background: #063f44;
    }
}