.lesson-life {
  display: flex;
  flex-direction: column;
}
.lesson-life .level-item {
  display: flex;
}
.lesson-life .level-item .level-title {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  min-height: 100px;
  width: 100px;
}
.lesson-life .level-item .level-title .pre-text {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 40px;
}
.lesson-life .level-item .level-title .number {
  font-size: 70px;
  padding-left: 30px;
}
.lesson-life .level-item .level-title.default {
  background-color: #9b9b9b;
}
.lesson-life .level-item .level-title.level1 {
  background-color: #00a484;
}
.lesson-life .level-item .level-title.level2 {
  background-color: #93bc48;
}
.lesson-life .level-item .level-title.level3 {
  background-color: #ff9700;
}
.lesson-life .level-item .level-title.level4 {
  background-color: #d1271e;
}
.lesson-life .level-item .level-title.expired {
  color: #ff0000;
}
.lesson-life .level-item .content {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
.lesson-life .level-item .content .tip {
  font-weight: bold;
}
.lesson-life .level-item .content .tip.level1 {
  color: #00a484;
}
.lesson-life .level-item .content .tip.level2 {
  color: #93bc48;
}
.lesson-life .level-item .content .tip.level3 {
  color: #ff9700;
}
.lesson-life .level-item .content .tip.level4 {
  color: #d1271e;
}
.lesson-life .level-item .content .course-table {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.lesson-life .level-item .content .course-table .course-table-row {
  display: flex;
  padding: 3px 0;
}
.lesson-life .level-item .content .course-table .course-table-row.default-header {
  background-color: #9b9b9b;
  color: #ffffff;
}
.lesson-life .level-item .content .course-table .course-table-row.level1 {
  border-bottom: 1px solid #00a484;
}
.lesson-life .level-item .content .course-table .course-table-row.level1-header {
  background-color: #00a484;
  color: #ffffff;
}
.lesson-life .level-item .content .course-table .course-table-row.level2-header {
  background-color: #93bc48;
  color: #ffffff;
}
.lesson-life .level-item .content .course-table .course-table-row.level2-expired-header {
  background-color: #bdda88;
  color: #ffffff;
}
.lesson-life .level-item .content .course-table .course-table-row.level3-header {
  background-color: #ff9700;
  color: #ffffff;
}
.lesson-life .level-item .content .course-table .course-table-row.level4-header {
  background-color: #d1271e;
  color: #ffffff;
}
.lesson-life .level-item .content .course-table .course-table-row.expired {
  color: #d1271e;
}
.lesson-life .level-item .content .course-table .course-table-row div {
  padding: 5px;
}
.lesson-life .level-item .content .course-table .course-table-row .expired {
  width: 110px;
}
.lesson-life .level-item .content .course-table .course-table-row .approved {
  width: 110px;
}
.lesson-life .level-item .content .course-table .course-table-row .type {
  width: 50px;
}
.lesson-life .level-item .content .course-table .course-table-row .end-date {
  width: 90px;
}
.lesson-life .level-item .content .course-table .course-table-row .download {
  width: 110px;
}
.lesson-life .level-item .content .course-table .course-table-row .title {
  flex: 1;
}
