.Signature {
    .Signature__controlArea {
        display: flex;
        padding: 5px 10px;

        button {
            background: #515bd4;
            padding: 5px 10px;
            margin: 0 10px;
            border: 0;
            border-radius: 5px;
            color: #ffffff;
            cursor: pointer;
        }
    }

    .Signature__content {
        padding: 5px 10px;
        height: 90vh;

        &.hide {
            display: none;
        }

        button {
            margin-right: 5px;
        }

        .signature-image {
            width: 250px;
            height: 150px;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
        }

        .delete-btn {
            background: #dc3545;
            padding: 5px 10px;
            border: 0;
            border-radius: 5px;
            color: #ffffff;
            cursor: pointer;

            &:hover {
                background: #c82333;
            }
        }

    }
}