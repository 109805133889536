.studentTable {
  width: 100%;
  border-radius: 10px;
}
.studentTable tr td:first-child {
  padding: 8px 10px;
  text-align: right;
}
.studentTable tr td .studentName,
.studentTable tr td .ownerTeacher {
  border: 1px #dddddd solid;
  border-radius: 5px;
  font-size: 18px;
  padding: 3px;
}
.studentTable select {
  width: 100%;
}
.studentTable input {
  width: 99%;
  margin: 0px 1px 1px 0px;
}
.studentTable label {
  cursor: pointer;
}
.studentTable .title {
  width: 150px;
}
.studentTable .uploadInput {
  display: none;
}
.studentTable .fileNameBox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.studentTable .fileNameBox .uploadLabel svg {
  font-size: 25px;
  color: #38b3bf;
  margin: 5px;
}
.studentTable .fileNameBox .video_block {
  width: 30%;
}
.studentTable .fileNameBox .fileName {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #999999;
  margin: 3px;
  padding: 3px;
  cursor: pointer;
}
.studentTable .fileNameBox .fileName .fileNameHeader {
  padding: 5px;
}
.studentTable .fileNameBox .fileName .fileNameHeader svg {
  font-size: 17px;
  margin: 0 5px;
}
.studentTable .fileNameBox .fileName .fileNameContent {
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.studentTable .fileNameBox .fileName .open_video {
  width: 125px;
  height: 125px;
  background-image: url(../../../Assets/movie_icon.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.studentTable .fileNameBox .fileName .open_pdf {
  width: 125px;
  height: 125px;
  background-image: url(../../../Assets/file_icon.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.actionButtonBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 15px 5px;
}
.actionButtonBox button {
  font-size: 16px;
  margin: 0 5px;
  color: #ffffff;
  padding: 5px 10px;
  border: 0px;
  background: #A246A5;
  cursor: pointer;
  border-radius: 5px;
}
.alertButtonBox {
  margin: 15px 5px;
}
.alertButtonBox button {
  font-size: 16px;
  margin: 0 5px;
  color: #ffffff;
  padding: 5px 10px;
  border: 0px;
  background: #A246A5;
  cursor: pointer;
  border-radius: 5px;
}
/* The Modal (background) */
.modal {
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: #000000;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}
/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  /* Could be more or less, depending on screen size */
}
.modal-content .remove {
  cursor: pointer;
  background: rgba(0, 0, 0, 0);
  border: 0px;
  color: white;
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
