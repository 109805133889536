.home {
    .logo {
        display            : block;
        width              : 500px;
        height             : 140px;
        background-size    : contain;
        background-repeat  : no-repeat;
        background-position: center;
        margin             : 0 auto;
    }

    input {
        width        : 250px;
        border-radius: 5px;
        padding      : 5px;
        font-size    : 15px;
    }
}