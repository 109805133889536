.ClassesManagement .title {
  color: #515bd4;
  font-size: 25px;
}
.ClassesManagement .ClassesManagement__controlArea {
  display: flex;
  padding: 5px 0;
  align-items: center;
}
.ClassesManagement .ClassesManagement__controlArea button {
  margin: 0 5px;
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.ClassesManagement .ClassesManagement__controlArea .ClassesManagement__controlArea__addClassArea {
  display: flex;
}
.ClassesManagement .ClassesManagement__controlArea .ClassesManagement__controlArea__addClassArea input {
  padding: 3px;
  border-radius: 5px;
  font-size: 15px;
  margin: 0 10px;
  width: 400px;
}
.ClassesManagement .ClassesManagement__controlArea .ClassesManagement__controlArea__addClassArea button.create {
  background: #337ab7;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  margin-left: 5px;
  cursor: pointer;
}
.ClassesManagement .ClassesManagement__controlArea .ClassesManagement__controlArea__addClassArea button.cancel {
  padding: 5px 10px;
  color: #721c24;
  background: #f8d7da;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
  margin: 0 5px;
}
.ClassesManagement table {
  width: 100%;
}
.ClassesManagement table thead {
  background: #515bd4;
  color: #ffffff;
}
.ClassesManagement table td {
  padding: 10px;
}
.ClassesManagement table tbody .loading {
  text-align: center;
}
.ClassesManagement table tbody tr:hover {
  background: #e2e0e0;
}
.ClassesManagement table tbody td {
  border-bottom: 1px solid #515bd4;
}
.ClassesManagement table tbody td svg {
  color: #000000;
}
.ClassesManagement table tbody td .ClassesMangement__content__deleteBtn {
  padding: 5px 15px;
  color: #721c24;
  background: #f8d7da;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
  margin: 0 5px;
}
.ClassesManagement table tbody td .ClassesMangement_content__deleteBtn:hover {
  background: #721c24;
  color: #f8d7da;
}
.ClassesManagement table tbody td .ClassesMangement__content__editBtn {
  padding: 5px 15px;
  color: #383d41;
  background: #e2e3e5;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
  margin: 0 5px;
}
.ClassesManagement table tbody td .ClassesMangement__content__editBtn:hover {
  background: #383d41;
  color: #e2e3e5;
}
.ClassesManagement table tbody td input {
  padding: 3px;
  border-radius: 5px;
  font-size: 15px;
  margin: 0 10px;
  width: 90%;
}
.ClassesManagement table tbody td:last-child {
  width: 140px;
}
.ClassesManagement table tbody td:nth-last-child(2) {
  width: 130px;
}
