.lesson-life {
    display: flex;
    flex-direction: column;

    .level-item {
        display: flex;

        .level-title {
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            color: #ffffff;
            min-height: 100px;
            width: 100px;

            .pre-text {
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                padding-top: 40px;
            }

            .number {
                font-size: 70px;
                padding-left: 30px;
            }

            &.default {
                background-color: #9b9b9b;
            }

            &.level1 {
                background-color: #00a484;
            }

            &.level2 {
                background-color: #93bc48;
            }

            &.level3 {
                background-color: #ff9700;
            }

            &.level4 {
                background-color: #d1271e;
            }

            &.expired {
                color: rgb(255, 0, 0);
            }
        }

        .content {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 10px;

            .tip {
                font-weight: bold;

                &.level1 {
                    color: #00a484;
                }

                &.level2 {
                    color: #93bc48;
                }

                &.level3 {
                    color: #ff9700;
                }

                &.level4 {
                    color: #d1271e;
                }
            }

            .course-table {
                display: flex;
                width: 100%;
                flex-direction: column;

                .course-table-row {
                    display: flex;
                    padding: 3px 0;

                    &.default-header {
                        background-color: #9b9b9b;
                        color: #ffffff;
                    }

                    &.level1 {
                        border-bottom: 1px solid #00a484;
                    }

                    &.level1-header {
                        background-color: #00a484;
                        color: #ffffff;
                    }

                    &.level2-header {
                        background-color: #93bc48;
                        color: #ffffff;
                    }

                    &.level2-expired-header {
                        background-color: #bdda88;
                        color: #ffffff;
                    }

                    &.level3-header {
                        background-color: #ff9700;
                        color: #ffffff;
                    }

                    &.level4-header {
                        background-color: #d1271e;
                        color: #ffffff;
                    }

                    &.expired {
                        color: #d1271e;
                    }

                    div {
                        padding: 5px;
                    }

                    .expired {
                        width: 110px;
                    }

                    .approved {
                        width: 110px;
                    }

                    .type {
                        width: 50px;
                    }

                    .end-date {
                        width: 90px;
                    }

                    .download {
                        width: 110px;
                    }

                    .title {
                        flex: 1;
                    }
                }
            }
        }
    }
}