.share-detail {
    display: flex;
    flex: 1;
    height: 70vh;
    flex-direction: column;
    align-content: center;
    margin: 30px 20px;

    .banner-title {
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 15px;
    }

    .row {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        width: 100%;

        .title {
            font-weight: bold;
            width: 100px;
        }

        .content {
            display: flex;
            flex: 1;

            &.ckeditor-content {
                min-height: 400px;
                max-height: 400px;
                overflow: auto;
                border: 1px solid #616161;
                display: block;
                padding: 0 15px;
            }

            input {
                flex: 1;
                border-radius: 5px;
                padding: 5px;
                font-size: 15px;
            }

            textarea {
                flex: 1;
                border-radius: 5px;
                padding: 5px;
                font-size: 15px;
                height: 500px;
                border: 2px solid #000000;
            }
        }

        &.buttons {
            justify-content: center;
            padding-top: 10px;

            button {
                background: #515bd4;
                padding: 5px 10px;
                margin: 0 10px;
                border: 0;
                border-radius: 5px;
                color: #ffffff;
                cursor: pointer;

                &:hover {
                    background: #444dad;
                }
            }

            .verify-area {
                display: flex;
                flex-direction: column;

                .row {
                    margin-top: 10px;

                    .delete-btn {
                        background: #dc3545;
                        padding: 5px 10px;
                        border: 0;
                        border-radius: 5px;
                        color: #ffffff;
                        cursor: pointer;

                        &:hover {
                            background: #c82333;
                        }
                    }

                    .warn-btn {
                        background: #ffc107;
                        padding: 5px 10px;
                        border: 0;
                        border-radius: 5px;
                        color: #ffffff;
                        cursor: pointer;

                        &:hover {
                            background: #e0a800;
                        }
                    }

                    input {
                        flex: 1;
                        border-radius: 5px;
                        padding: 5px;
                        font-size: 15px;
                    }
                }
            }
        }
    }
}