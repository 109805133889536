.certification {
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin: 0 auto;

    .certification_title {
        text-align: center;
        font-weight: bold;
        font-size: 32px;
        padding: 30px 0;
    }

    .search-panel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .row {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 20px;

            .title {
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;
                padding-right: 10px;
                font-size: 20px;
            }

            .btn {                
                color: #ffffff;
                padding: 5px 10px;
                border: 1px solid #ffffff;
                background: #871e8c;
                cursor: pointer;
                border-radius: 10px;
                flex: 1;
                font-size: 14px;
            }

            &.response{
                color:#cf4c0f;
            }
        }
    }

    .search-result{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        .row {
            width: 100%;
            display: flex;
            flex: 1;
            margin-top: 10px;
            div{
                padding: 5px 15px;
            }
            &.header {
                background: #871e8c;
                color: #ffffff;
            }

            .name{
                width: 100px;
            }
            .certification-code{
                width: 150px;
            }
            .pass-date, .end-date{
                width: 100px;
            }
            .content{
                flex: 1;
            }
        }
    }
}