.MemberSharesManage .MemberCertificationsManage__searchArea {
  padding: 5px 0;
}
.MemberSharesManage .MemberCertificationsManage__searchArea input {
  padding: 3px;
  border-radius: 5px;
  font-size: 15px;
  margin: 0 10px;
  width: 400px;
}
.MemberSharesManage .MemberCertificationsManage__searchArea button {
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 5px;
}
.MemberSharesManage .MemberSharesManage__content {
  height: 75vh;
  overflow: auto;
}
.MemberSharesManage .MemberSharesManage__content.hide {
  display: none;
}
.MemberSharesManage .MemberSharesManage__content .control-area {
  display: flex;
  align-items: center;
}
.MemberSharesManage .MemberSharesManage__content .control-area .reject-block {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dotted #17a2b8;
  border-radius: 50%;
  background: #ffffff;
  width: 30px;
  height: 30px;
  color: #17a2b8;
}
.MemberSharesManage .MemberSharesManage__content .MuiDataGrid-columnsContainer {
  background: #515bd4;
  color: #ffffff;
}
.MemberSharesManage .MemberSharesManage__content button {
  margin-right: 5px;
}
.MemberSharesManage .MemberSharesManage__content .delete-btn {
  background: #dc3545;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.MemberSharesManage .MemberSharesManage__content .delete-btn:hover {
  background: #c82333;
}
.MemberSharesManage .MemberSharesManage__content .update-btn {
  background: #007bff;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.MemberSharesManage .MemberSharesManage__content .update-btn:hover {
  background: #0069d9;
}
.MemberSharesManage .MemberSharesManage__content .info-btn {
  background: #17a2b8;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.MemberSharesManage .MemberSharesManage__content .info-btn:hover {
  background: #138496;
}
.MemberSharesManage .MemberSharesManage__content .warn-btn {
  background: #ffc107;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.MemberSharesManage .MemberSharesManage__content .warn-btn:hover {
  background: #e0a800;
}
.MemberSharesManage .MemberSharesManage__content .cancel-btn {
  background: #6c757d;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.MemberSharesManage .MemberSharesManage__content .cancel-btn:hover {
  background: #5a6268;
}
.MemberSharesManage .MemberSharesManage__content .success-btn {
  background: #28a745;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.MemberSharesManage .MemberSharesManage__content .success-btn:hover {
  background: #218838;
}
