.Certificates{
	.Certificates__controlArea{
        display: flex;
        padding: 5px 10px;
        svg{
            margin:0 15px;
        }

		select {
			padding: 3px;
			border-radius: 5px;
			font-size: 15px;			
			width: 400px;
        }
        
        button{
			background: #515bd4;
            padding: 5px 10px;
            margin: 0 10px;
			border: 0;
			border-radius: 5px;
			color: #ffffff;
			cursor: pointer;
		}
    }

    .Certificates__content{
        padding: 5px 10px;
        .Certificates__content__desc{
            padding: 3px;
			border-radius: 5px;
            font-size: 15px;
            width: 500px;
            height: 80px;
        }
        .Certificates__content__certificate{
            width: 500px;
            border:1px solid #818181;    
            border-radius: 5px;
            .Certificates__content__controll{
                display: flex;
                flex-direction: row-reverse;
                padding: 5px 5px;
                background: #818181;
                svg{
                    cursor:pointer;
                    font-size: 25px;
                    margin: 0 5px;
                    color:#ffffff;
                }

                .uploadInput{
                    display:none;
                }
            }
    
            .Certificates__content__image{                            
                height: 600px;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
            }
        }        
    }
}