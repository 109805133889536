.resourceBody {
  margin: 5px 10px;
}

.resourceTile {
  font-size: 1.17em;
}

.resourceTable {
  // border      : 1px solid;
  width          : 100%;
  border-collapse: collapse;

  .fileUpBox {
    display: flex;

    >div {
      flex         : 1;
      margin       : 5px;
      border-radius: 5px;
      background   : #c4c4c4;
      height       : 40px;
      line-height  : 40px;      
      font-weight  : bold;

      label>div{
        color        : #858585;
      }

      .uploadedFile {
        position: relative;
        color  : #000000;
        cursor: pointer;

        >div:first-child{
          position: absolute;
          padding: 0 15px;
          font-size: 20px;
        }

        svg {
          cursor:pointer;
        }

        .uploadFileName {
          position: absolute;
          height: 40px;
          left: 45px;
          width: 90%;
          overflow: hidden;
          text-align: center;
        }
      }
    }
  }

  .title {
    width     : 200px;
    text-align: center;
    padding   : 10px 0;
  }

  .upFile {
    background: grey;
  }

  .file {
    display: none;
  }

  label {
    cursor    : pointer;
    background: gray;
    text-align: center;
  }

}