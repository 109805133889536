.signInbarBanner {
  background: #871e8c;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.signInbarBanner .signInForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.signInbarBanner .signInForm .signInForm__title {
  color: #ffffff;
  font-size: 40px;
  background: #68056B;
  font-weight: bold;
  padding: 10px 0;
  text-align: center;
}
.signInbarBanner .signInForm .signInForm__content {
  margin: 0 auto;
}
.signInbarBanner .signInForm .signInForm__content .forget-password-btn {
  text-align: center;
  text-decoration: none;
}
.signInbarBanner .signInForm .signInForm__content .forget-password-btn:hover {
  background: #68056B;
}
.signInbarBanner .signInForm .signInForm__content .inputtext-email {
  width: 100%;
}
.signInbarBanner .signInForm .inputBox {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 330px;
}
.signInbarBanner .signInForm .inputBox label {
  font-family: 'PingFangTC-Regular', 'PingFang TC';
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  color: #FFFFFF;
}
.signInbarBanner .signInForm .btnBox {
  display: flex;
  justify-content: center;
  width: 330px;
  align-self: center;
  padding: 2px 2px 2px 2px;
  box-sizing: border-box;
  padding-top: 20px;
}
.signInbarBanner .signInForm .btnBox .btn {
  font-size: 14px;
  color: #ffffff;
  padding: 10px 20px;
  border: 1px solid #ffffff;
  background: #871e8c;
  cursor: pointer;
  border-radius: 5px;
  flex: 1;
}
.signInbarBanner .signInForm .btnBox button:hover {
  background: #68056B;
}
