.CourseType {
    .CourseType__title {
        font-size  : 20px;
        font-weight: bold;
        padding    : 30px 10px;
    }

    .CourseType__content {
        table {
            margin:0 auto;
            width: 80%;

            thead {
                background: #515bd4;
                color     : #ffffff;
            }

            td {
                padding: 10px;
            }

            tbody {
                .loading {
                    text-align: center;
                }

                tr:hover {
                    background: #e2e0e0;
                }

                td {
                    border-bottom: 1px solid #515bd4;

                    svg {
                        color: #000000;
                    }
                }
            }
        }
    }
}