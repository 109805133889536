.ApprovedMemberLessonPlans {
    height: 80vh;

    .MemberLessonPlans__content {
        height: 75vh;

        .MuiDataGrid-columnsContainer {
            background: #515bd4;
            color     : #ffffff;
        }

        .MuiDataGrid-cell {
            .approveBtn {
                background   : #337ab7;
                padding      : 5px 10px;
                border       : 0;
                border-radius: 5px;
                color        : #ffffff;
                margin-left  : 5px;
                cursor       : pointer;
            }

            .readBtn {
                background   : #5cb85c;
                padding      : 5px 10px;
                border       : 0;
                border-radius: 5px;
                color        : #ffffff;
                margin-left  : 5px;
                cursor       : pointer;
            }

            .readBtn:hover {
                background: #4cae4c;
            }

            .cancelBtn {
                background   : #c9302c;
                padding      : 5px 10px;
                border       : 0;
                border-radius: 5px;
                color        : #ffffff;
                margin-left  : 5px;
                cursor       : pointer;
            }

            .cancelBtn:hover {
                background: #ac2925;
            }
        }

        table {
            width   : 100%;
            overflow: auto;

            thead {
                background: #515bd4;
                color     : #ffffff;
            }

            td {
                padding: 10px;
            }

            tbody {
                .loading {
                    text-align: center;
                }

                tr:hover {
                    background: #e2e0e0;
                }

                td {
                    border-bottom: 1px solid #515bd4;

                    .approveBtn {
                        background   : #337ab7;
                        padding      : 5px 10px;
                        border       : 0;
                        border-radius: 5px;
                        color        : #ffffff;
                        margin-left  : 5px;
                        cursor       : pointer;
                    }

                    .readBtn {
                        background   : #5cb85c;
                        padding      : 5px 10px;
                        border       : 0;
                        border-radius: 5px;
                        color        : #ffffff;
                        margin-left  : 5px;
                        cursor       : pointer;
                    }

                    .readBtn:hover {
                        background: #4cae4c;
                    }

                    .cancelBtn {
                        background   : #c9302c;
                        padding      : 5px 10px;
                        border       : 0;
                        border-radius: 5px;
                        color        : #ffffff;
                        margin-left  : 5px;
                        cursor       : pointer;
                    }

                    .cancelBtn:hover {
                        background: #ac2925;
                    }
                }
            }
        }
    }

}