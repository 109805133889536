.Login{		
	.content{
		margin: 30px auto;
		width: 700px;
		background: #7f1083;
		padding: 15px;		
		.logo{			
			height: 200px;
			background-size:contain;
			background-position: center;
			background-repeat: no-repeat;
		}

		.title{
			font-size: 30px;
			color: #ffffff;
			text-align: center;
			font-weight: bold;
			padding: 5px 0;
		}

		.form{
			display: flex;
			flex-direction: column;
			align-items: center;

			.row{
				display: flex;	
				padding: 3px 0;			
			}
		}

		.loginform{
			color: #ffffff;

			.label{
				font-size: 20px;
				margin: 0 10px;
			}

			.textInput{
				width: 250px;
				border-radius: 5px;				
				padding: 5px;
				font-size: 15px;
			}

			.loginBtn{
				border-radius: 5px;
				background: #ffffff;
				color: #7f1083;
				padding: 5px 20px;
				margin: 10px 0;
				cursor: pointer;
				font-weight: bold;
				height: 30px;
			}

			.loginBtn:hover{
				background: lightgray;
			}

		}

		.btn{
			border-radius: 5px;
		}

		.errorMsg{
			height: 50px;
			color: #ff0000;
			font-size: 15px;
		}
	}	
}