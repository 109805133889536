.TeacherAbilityDisplay .TeacherAbilityDisplay_content-area .TeacherAbilityDisplay__Pointers {
  display: flex;
  flex-direction: column;
  border: 2px solid #40619d;
  border-radius: 5px;
  margin-bottom: 10px;
}
.TeacherAbilityDisplay .TeacherAbilityDisplay_content-area .TeacherAbilityDisplay__Pointers .TeacherAbilityDisplay__Class {
  color: #ffffff;
  background-color: #697b9c;
  padding: 5px 10px;
  font-size: 20px;
}
.TeacherAbilityDisplay .TeacherAbilityDisplay_content-area .TeacherAbilityDisplay__Pointers .TeacherAbilityDisplay__ClassContent {
  padding: 20px;
}
.TeacherAbilityDisplay .TeacherAbilityDisplay_content-area .TeacherAbilityDisplay__Pointers .TeacherAbilityDisplay__ClassContent .TeacherAbilityDisplay__ClassDescription {
  padding: 0 5px 10px 5px;
}
.TeacherAbilityDisplay .TeacherAbilityDisplay_content-area .TeacherAbilityDisplay__Pointers .TeacherAbilityDisplay__ClassContent .TeacherAbilityDisplay__PointerItem {
  border-radius: 5px;
  margin-bottom: 5px;
  display: flex;
}
.TeacherAbilityDisplay .TeacherAbilityDisplay_content-area .TeacherAbilityDisplay__Pointers .TeacherAbilityDisplay__ClassContent .TeacherAbilityDisplay__PointerItem .TeacherAbilityDisplay__PointerItem_title {
  flex: 1;
  color: #ffffff;
  background-color: #697b9c;
  padding: 5px 10px;
  font-size: 20px;
}
.TeacherAbilityDisplay .TeacherAbilityDisplay_content-area .TeacherAbilityDisplay__Pointers .TeacherAbilityDisplay__ClassContent .TeacherAbilityDisplay__PointerItem .TeacherAbilityDisplay__PointerItem_content {
  flex: 1;
  display: flex;
}
.TeacherAbilityDisplay .TeacherAbilityDisplay_content-area .TeacherAbilityDisplay__Pointers .TeacherAbilityDisplay__ClassContent .TeacherAbilityDisplay__PointerItem .TeacherAbilityDisplay__PointerItem_content label {
  display: flex;
  flex: 1;
}
.TeacherAbilityDisplay .TeacherAbilityDisplay_content-area .TeacherAbilityDisplay__Pointers .TeacherAbilityDisplay__ClassContent .TeacherAbilityDisplay__PointerItem .TeacherAbilityDisplay__PointerItem_content label .TeacherAbilityDisplay__PointerItem_Option {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5px 5px 10px 5px;
  background-color: #e0eff5;
  border: 1px solid #ffffff;
}
.TeacherAbilityDisplay .TeacherAbilityDisplay_content-area .TeacherAbilityDisplay__Pointers .TeacherAbilityDisplay__ClassContent .TeacherAbilityDisplay__PointerItem .TeacherAbilityDisplay__PointerItem_content label .TeacherAbilityDisplay__PointerItem_Option .TeacherAbilityDisplay__PointerItem_Option_Title {
  padding-bottom: 15px;
}
