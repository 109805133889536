.instancesTable {
  width: 100%;
  border-collapse: collapse;
}
.instancesTable > tbody > tr > td:nth-child(2) {
  padding: 10px 10px;
}
.instancesTable > tbody > tr > td:nth-child(2) .step {
  padding: 5px 2px;
  width: 150px;
  font-size: 15px;
}
.instancesTable > tbody > tr > td:nth-child(2) .sourceUrl {
  width: 400px;
  border-radius: 5px;
  padding: 5px;
  font-size: 15px;
}
.instancesTable > tbody > tr > td:nth-child(2) .addSourceUrl {
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 5px;
  cursor: pointer;
  background: #d4d3d3;
}
.instancesTable > tbody > tr > td:nth-child(2) .resourceList .resourceItem {
  display: flex;
  padding: 3px 5px;
  border-radius: 5px;
  margin: 3px 0;
  background: #dddddd;
  cursor: pointer;
  font-size: 18px;
}
.instancesTable > tbody > tr > td:nth-child(2) .resourceList .resourceItem .removeBtn {
  color: #990101;
  margin: 0 5px;
}
.instancesTable > tbody > tr > td:nth-child(2) .resourceList .resourceItem:hover {
  background: #b4b4b4;
}
.instancesTable .tableHeader {
  background: #dddddd;
  display: flex;
  justify-content: space-between;
}
.instancesTable .tableHeader > svg {
  font-size: 20px;
  margin: 5px;
  cursor: pointer;
}
.instancesTable .tableHeader .deleteInstances {
  cursor: pointer;
}
.instancesTable .tableHeader .deleteInstances svg {
  font-size: 20px;
  margin: 5px 10px;
}
.instancesTable td.warning {
  background: #ffab84;
}
.instancesTable td.warning .notice {
  color: red;
}
.instancesTable .instancesTitle {
  padding: 10px 10px;
  width: 150px;
  text-align: right;
  font-weight: bold;
}
.instancesTable .instancesTitle .notice {
  color: red;
}
.instancesTable .instancesTitle .teacherAbilityManageBtn {
  background: #8f6791;
  padding: 4px 15px;
  margin: 10px 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.instancesTable .instancesTitle.warning {
  background: #ffab84;
}
.instancesTable .removeBtn {
  cursor: pointer;
}
.instancesTable .student {
  margin: 0 auto;
  width: 80%;
  border-style: solid;
  border-collapse: collapse;
}
.instancesTable .student tr td {
  width: 200px;
  padding: 10px 0;
  text-align: center;
}
.instancesTable .student .editorBtn {
  font-size: 16px;
  color: #ffffff;
  padding: 5px 10px;
  border: 0px;
  background: #A246A5;
  cursor: pointer;
  border-radius: 5px;
}
.instancesTable .resourceArea .carete-resource-btn {
  font-size: 16px;
  color: #ffffff;
  padding: 5px 10px;
  border: 0px;
  background: #38b3bf;
  cursor: pointer;
  border-radius: 5px;
  margin: 5px 3px;
}
.instancesTable .resourceArea .carete-resource-btn:hover {
  background: #063f44;
}
.instancesTable .resourceArea .RecommendResources__content__editBtn,
.instancesTable .resourceArea .RecommendResources__content__deleteBtn {
  font-size: 16px;
  color: #ffffff;
  padding: 5px 10px;
  border: 0px;
  background: #A246A5;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 3px;
}
.instancesTable .resourceArea .RecommendResources__content__editBtn:hover,
.instancesTable .resourceArea .RecommendResources__content__deleteBtn:hover {
  background: #750d47;
}
.instancesTable .resourceArea .resource-item {
  display: flex;
  align-items: center;
  padding: 3px 3px;
  border-bottom: 2px solid #b4b4b4;
}
.instancesTable .resourceArea .resource-item:hover {
  background: #b4b4b4;
}
.instancesTable .demoImg {
  width: 120px;
  height: 60px;
}
.instancesTable .photoList,
.instancesTable .videoList {
  display: flex;
}
.instancesTable .photoList .photoItem,
.instancesTable .videoList .photoItem,
.instancesTable .photoList .videoItem,
.instancesTable .videoList .videoItem {
  width: 33%;
  margin: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.instancesTable .photoList .photoItem .videoItem__top,
.instancesTable .videoList .photoItem .videoItem__top,
.instancesTable .photoList .videoItem .videoItem__top,
.instancesTable .videoList .videoItem .videoItem__top,
.instancesTable .photoList .photoItem .photoItem__top,
.instancesTable .videoList .photoItem .photoItem__top,
.instancesTable .photoList .videoItem .photoItem__top,
.instancesTable .videoList .videoItem .photoItem__top {
  display: flex;
  width: 100%;
}
.instancesTable .photoList .photoItem .photo__btn-remove,
.instancesTable .videoList .photoItem .photo__btn-remove,
.instancesTable .photoList .videoItem .photo__btn-remove,
.instancesTable .videoList .videoItem .photo__btn-remove,
.instancesTable .photoList .photoItem .video__btn-remove,
.instancesTable .videoList .photoItem .video__btn-remove,
.instancesTable .photoList .videoItem .video__btn-remove,
.instancesTable .videoList .videoItem .video__btn-remove {
  cursor: pointer;
  color: #990101;
  padding-left: 10px;
  font-size: 20px;
}
.instancesTable .photoList .photoItem .photo__btn-remove:hover,
.instancesTable .videoList .photoItem .photo__btn-remove:hover,
.instancesTable .photoList .videoItem .photo__btn-remove:hover,
.instancesTable .videoList .videoItem .photo__btn-remove:hover,
.instancesTable .photoList .photoItem .video__btn-remove:hover,
.instancesTable .videoList .photoItem .video__btn-remove:hover,
.instancesTable .photoList .videoItem .video__btn-remove:hover,
.instancesTable .videoList .videoItem .video__btn-remove:hover {
  background: #dddddd;
}
.instancesTable .photoList .photoItem .photo,
.instancesTable .videoList .photoItem .photo,
.instancesTable .photoList .videoItem .photo,
.instancesTable .videoList .videoItem .photo,
.instancesTable .photoList .photoItem .video,
.instancesTable .videoList .photoItem .video,
.instancesTable .photoList .videoItem .video,
.instancesTable .videoList .videoItem .video {
  width: 100%;
  height: 200px;
  border: 1px solid #dddddd;
  border-radius: 5px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 0 10px;
}
.instancesTable .photoList .photoItem .photo__content,
.instancesTable .videoList .photoItem .photo__content,
.instancesTable .photoList .videoItem .photo__content,
.instancesTable .videoList .videoItem .photo__content,
.instancesTable .photoList .photoItem .video__content,
.instancesTable .videoList .photoItem .video__content,
.instancesTable .photoList .videoItem .video__content,
.instancesTable .videoList .videoItem .video__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 10px;
}
.instancesTable .photoList .photoItem .photo__content .photo__content__title,
.instancesTable .videoList .photoItem .photo__content .photo__content__title,
.instancesTable .photoList .videoItem .photo__content .photo__content__title,
.instancesTable .videoList .videoItem .photo__content .photo__content__title,
.instancesTable .photoList .photoItem .video__content .photo__content__title,
.instancesTable .videoList .photoItem .video__content .photo__content__title,
.instancesTable .photoList .videoItem .video__content .photo__content__title,
.instancesTable .videoList .videoItem .video__content .photo__content__title,
.instancesTable .photoList .photoItem .photo__content .video__content__title,
.instancesTable .videoList .photoItem .photo__content .video__content__title,
.instancesTable .photoList .videoItem .photo__content .video__content__title,
.instancesTable .videoList .videoItem .photo__content .video__content__title,
.instancesTable .photoList .photoItem .video__content .video__content__title,
.instancesTable .videoList .photoItem .video__content .video__content__title,
.instancesTable .photoList .videoItem .video__content .video__content__title,
.instancesTable .videoList .videoItem .video__content .video__content__title {
  font-size: 20px;
  font-weight: bold;
}
.instancesTable .photoList .photoItem .photo__content .photo__content__desc,
.instancesTable .videoList .photoItem .photo__content .photo__content__desc,
.instancesTable .photoList .videoItem .photo__content .photo__content__desc,
.instancesTable .videoList .videoItem .photo__content .photo__content__desc,
.instancesTable .photoList .photoItem .video__content .photo__content__desc,
.instancesTable .videoList .photoItem .video__content .photo__content__desc,
.instancesTable .photoList .videoItem .video__content .photo__content__desc,
.instancesTable .videoList .videoItem .video__content .photo__content__desc,
.instancesTable .photoList .photoItem .photo__content .video__content__desc,
.instancesTable .videoList .photoItem .photo__content .video__content__desc,
.instancesTable .photoList .videoItem .photo__content .video__content__desc,
.instancesTable .videoList .videoItem .photo__content .video__content__desc,
.instancesTable .photoList .photoItem .video__content .video__content__desc,
.instancesTable .videoList .photoItem .video__content .video__content__desc,
.instancesTable .photoList .videoItem .video__content .video__content__desc,
.instancesTable .videoList .videoItem .video__content .video__content__desc {
  word-break: break-all;
}
.instancesTable .uploadLabel {
  cursor: pointer;
}
.instancesTable .uploadInput {
  display: none;
}
.addStudent,
.addFile {
  font-size: 16px;
  color: #ffffff;
  padding: 5px 10px;
  border: 0px;
  background: #38b3bf;
  cursor: pointer;
  border-radius: 5px;
  margin: 3px 0;
}
.addStudent:hover,
.addFile:hover {
  background: #063f44;
}
