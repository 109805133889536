.PointerManagement .PointerManagement__controlArea {
  display: flex;
  padding: 5px 0;
}
.PointerManagement .PointerManagement__controlArea button {
  margin: 0 5px;
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.PointerManagement .PointerManagement__content .PointerManagement__header {
  display: flex;
}
.PointerManagement .PointerManagement__content .PointerManagement__header div {
  flex: 1;
  color: #ffffff;
  background: #515bd4;
  padding: 10px;
}
.PointerManagement .PointerManagement__content .PointerManagement__header div:last-child {
  width: 140px;
}
.PointerManagement .PointerManagement__content .PointerManagement__items :hover {
  background: #e2e0e0;
}
.PointerManagement table {
  width: 100%;
}
.PointerManagement table thead {
  background: #515bd4;
  color: #ffffff;
}
.PointerManagement table td {
  padding: 10px;
}
.PointerManagement table tbody .loading {
  text-align: center;
}
.PointerManagement table tbody tr:hover {
  background: #e2e0e0;
}
.PointerManagement table tbody td {
  border-bottom: 1px solid #515bd4;
}
.PointerManagement table tbody td svg {
  color: #000000;
}
.PointerManagement table tbody td .PointerManagement__content__deleteBtn {
  padding: 5px 15px;
  color: #721c24;
  background: #f8d7da;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
  margin: 0 5px;
}
.PointerManagement table tbody td .PointerManagement__content__deleteBtn:hover {
  background: #721c24;
  color: #f8d7da;
}
.PointerManagement table tbody td .PointerManagement__content__editBtn {
  padding: 5px 15px;
  color: #383d41;
  background: #e2e3e5;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
  margin: 0 5px;
}
.PointerManagement table tbody td .PointerManagement__content__editBtn:hover {
  background: #383d41;
  color: #e2e3e5;
}
.PointerManagement table tbody td .PointerManagement_item {
  width: 200px;
  max-height: 300px;
  white-space: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.PointerManagement table tbody td .PointerManagement_item::-webkit-scrollbar {
  display: none;
}
.PointerManagement table tbody td:last-child {
  width: 140px;
}
.PointerManagement table tbody td:nth-last-child(2) {
  width: 130px;
}
