.MemberCertificationDetail{
	a {
        color: #0000EE;
        cursor: pointer;
	}
	
	.title{
		padding-bottom: 10px;
		font-size: 20px;
		font-weight: bold;
	}
    .MemberCertificationDetail__content {        
		height: 90vh;
		overflow: auto;

		.title{
			padding-bottom: 15px;
			font-weight: bold;
			font-size: 20px;
		}

		.teacher-lessonplans{
			height: 220px;
		}

		.teacher-courses{
			height: 400px;
		}

		.warning{
			padding: 10px;
			margin: 10px 0;
			border-radius: 5px;
			background: #fff3cd;
			color: #856404;
		}
				
		.MuiDataGrid-columnsContainer {
            background: #515bd4;
            color     : #ffffff;
		}

		.certification-form-controller{
			display: flex;
			justify-content: center;
			padding-top: 15px;

			button{
				background: #515bd4;
				padding: 5px 10px;
				border: 0;
				font-size:15px;
				border-radius: 5px;
				color: #ffffff;
				cursor: pointer;
			}
		}
    }
}
