.Admin a {
  text-decoration: none;
}
.ReactModalPortal .modalControllArea,
.MuiDialogTitle-root .modalControllArea {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.ReactModalPortal .modalControllArea svg,
.MuiDialogTitle-root .modalControllArea svg {
  cursor: pointer;
}
.ReactModalPortal .modalControllArea > .title,
.MuiDialogTitle-root .modalControllArea > .title {
  flex: 1;
  font-size: 25px;
  font-weight: bold;
}
.Admin__row {
  display: flex;
  padding: 5px 0;
}
.Admin__row input {
  padding: 3px;
  border-radius: 5px;
  font-size: 15px;
  width: 90%;
}
.Admin__row textarea {
  padding: 3px;
  border-radius: 5px;
  font-size: 15px;
  width: 90%;
  border: 2px solid black;
}
.Admin__row .item_required::before {
  content: "*";
  color: #fc0202;
}
.Admin__row .Admin__row__item {
  flex: 1;
}
.Admin__row .Admin__row__item2 {
  flex: 2;
}
.Admin__row .Admin__row__item3 {
  flex: 3;
}
.Admin__row .Admin__row__item4 {
  flex: 4;
}
.Admin__row.underline {
  border-bottom: 1px solid #7f1083;
}
