.certification {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0 auto;
}
.certification .certification_title {
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  padding: 30px 0;
}
.certification .search-panel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.certification .search-panel .row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.certification .search-panel .row .title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding-right: 10px;
  font-size: 20px;
}
.certification .search-panel .row .btn {
  color: #ffffff;
  padding: 5px 10px;
  border: 1px solid #ffffff;
  background: #871e8c;
  cursor: pointer;
  border-radius: 10px;
  flex: 1;
  font-size: 14px;
}
.certification .search-panel .row.response {
  color: #cf4c0f;
}
.certification .search-result {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.certification .search-result .row {
  width: 100%;
  display: flex;
  flex: 1;
  margin-top: 10px;
}
.certification .search-result .row div {
  padding: 5px 15px;
}
.certification .search-result .row.header {
  background: #871e8c;
  color: #ffffff;
}
.certification .search-result .row .name {
  width: 100px;
}
.certification .search-result .row .certification-code {
  width: 150px;
}
.certification .search-result .row .pass-date,
.certification .search-result .row .end-date {
  width: 100px;
}
.certification .search-result .row .content {
  flex: 1;
}
