.Resource .Resource__title {
  color: #232ca0;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 18px;
}
.Resource .controlArea {
  flex-direction: row-reverse;
  justify-content: center;
  padding: 30px 0;
}
.Resource .controlArea .saveBtn {
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  font-size: 15px;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.Resource .articleDescription {
  height: 200px;
}
.Resource select {
  padding: 3px;
  border-radius: 5px;
  font-size: 15px;
  width: 70%;
  border: 2px solid black;
}
.Resource button {
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  font-size: 15px;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
  margin: 0 5px;
}
.Resource .classDisplayArea {
  display: flex;
}
.Resource .classDisplayArea .classItem {
  display: flex;
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  font-size: 15px;
  border-radius: 5px;
  color: #ffffff;
  margin: 0 5px;
}
.Resource .classDisplayArea .classItem svg {
  margin-left: 5px;
  cursor: pointer;
}
