.planBasicContent {
  margin: 5px 10px;
}
.planBasicContent table {
  width: 100%;
  border-collapse: collapse;
}
.planBasicContent table button {
  cursor: pointer;
  background: white;
  margin: 0px 3px;
  border-radius: 3px;
}
.planBasicContent table td {
  border-collapse: collapse;
  border: 1px solid;
  padding: 5px 2px;
  border-radius: 5px;
}
.planBasicContent table td .academicYear,
.planBasicContent table td .grade {
  padding: 5px 2px;
  width: 150px;
  font-size: 15px;
}
.planBasicContent table .basicLable {
  text-align: center;
  width: 200px;
}
.planBasicContent table .basicLable .teacherAbilityManageBtn {
  background: #8f6791;
  padding: 4px 15px;
  margin: 10px 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.planBasicContent table .planCreater {
  background: #871e8c;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  margin: 3px 3px;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
}
.planBasicContent table .notice {
  color: red;
}
.planBasicContent table .creater > svg {
  margin: 0 5px;
}
.planBasicContent table .creater .search {
  width: 400px;
  border-radius: 5px;
  padding: 5px;
  font-size: 15px;
}
.planBasicContent table .creater .searchBtn {
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 5px;
  background: #d4d3d3;
}
.planBasicContent table .creater .searchBtn:hover {
  background: #b1b0b0;
}
.planBasicContent table .creater .waitAdd {
  background: #f350fc;
  color: #ffffff;
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 5px;
}
.planBasicContent table .deleteMember {
  background: #871e8c;
  color: #ffffff;
  font-size: 15px;
  margin: 3px 3px;
  padding: 5px 10px;
  border-radius: 5px;
}
.planBasicContent table .deleteMember .fa-times {
  margin: 0 2px;
}
.planBasicContent table .keywordBox {
  font-size: 14px;
  margin: 2px 0px;
  display: flex;
}
.planBasicContent table .keywordBox div {
  vertical-align: middle;
  padding: 5px 5px;
}
.planBasicContent table .keywordBox .keywordInput {
  width: 400px;
  border-radius: 5px;
  padding: 5px;
  font-size: 15px;
}
.planBasicContent table .keywordBox button {
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 5px;
  background: #d4d3d3;
}
.planBasicContent table .keywordBox button:hover {
  background: #b1b0b0;
}
.planBasicContent table .keyWordList .keywordBtn {
  border: 1px solid;
  padding: 5px 10px;
  margin: 0px 2px;
  border-radius: 5px;
}
.planBasicContent table .keyWordList .keywordBtn.changed {
  color: #fff;
  background: purple;
  border-color: #fff;
}
.planBasicContent table .selfKeyWord {
  border: 1px solid;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 0px 2px;
}
.planBasicContent table .selfKeyWord.changed {
  color: #fff;
  background: purple;
  border-color: #fff;
}
.planBasicContent table .description {
  width: 99%;
  padding: 0px 3px;
  margin: 1px 0px;
  resize: none;
}
.planBasicContent table .title {
  width: 95%;
  border-radius: 5px;
  padding: 5px;
  font-size: 15px;
  margin-left: 2px;
}
