.MemberApproveCertification .MemberManagement__searchArea {
  padding: 5px 0;
}
.MemberApproveCertification .MemberManagement__searchArea input {
  padding: 3px;
  border-radius: 5px;
  font-size: 15px;
  margin: 0 10px;
  width: 400px;
}
.MemberApproveCertification .MemberManagement__searchArea button {
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.MemberApproveCertification .teacher-lessonplans {
  height: 300px;
}
.MemberApproveCertification .teacher-courses {
  height: 300px;
}
.MemberApproveCertification .MemberApproveCertification__content {
  height: 90vh;
  overflow: auto;
}
.MemberApproveCertification .MemberApproveCertification__content .title {
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 20px;
}
.MemberApproveCertification .MemberApproveCertification__content .warning {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  background: #fff3cd;
  color: #856404;
}
.MemberApproveCertification .MemberApproveCertification__content .MuiDataGrid-columnsContainer {
  background: #515bd4;
  color: #ffffff;
}
.MemberApproveCertification .MemberApproveCertification__content .certification-form-controller {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}
.MemberApproveCertification .MemberApproveCertification__content .certification-form-controller button {
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  font-size: 15px;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.MemberApproveCertification .certification-form input {
  margin-left: 20px;
}
