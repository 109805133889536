@btn-color: #871e8c;

.planBasicContent {
    margin: 5px 10px;

    table {

        width          : 100%;
        border-collapse: collapse;

        button {
            cursor       : pointer;
            background   : white;
            margin       : 0px 3px;
            border-radius: 3px;
        }

        td {
            border-collapse: collapse;
            border         : 1px solid;
            padding        : 5px 2px;
            border-radius  : 5px;

            .academicYear,
            .grade {
                padding  : 5px 2px;
                width    : 150px;
                font-size: 15px;
            }

        }

        .basicLable {
            text-align: center;
            width     : 200px;

            .teacherAbilityManageBtn {
                background   : #8f6791;
                padding      : 4px 15px;
                margin       : 10px 0;
                border-radius: 5px;
                color        : #ffffff;
                cursor       : pointer;
            }
        }

        .planCreater {
            background    : #871e8c;
            color         : #ffffff;
            display       : inline-block;
            vertical-align: middle;
            margin        : 3px 3px;
            padding       : 5px 10px;
            border-radius : 5px;
            text-align    : center;

        }

        .notice {
            color: red;
        }

        .creater {

            >svg {
                margin: 0 5px;
            }

            .search {
                width        : 400px;
                border-radius: 5px;
                padding      : 5px;
                font-size    : 15px;
            }

            .searchBtn {
                padding      : 5px 10px;
                margin       : 0px 5px;
                border-radius: 5px;
                background   : #d4d3d3;
            }

            .searchBtn:hover {
                background: #b1b0b0;
            }

            .waitAdd {
                background   : #f350fc;
                color        : #ffffff;
                padding      : 5px 10px;
                font-size    : 15px;
                border-radius: 5px;
            }
        }

        .deleteMember {
            background   : @btn-color;
            color        : #ffffff;
            border-radius: 5px;
            font-size    : 15px;
            margin       : 3px 3px;
            padding      : 5px 10px;
            border-radius: 5px;

            .fa-times {
                margin: 0 2px;
            }
        }

        .keywordBox {
            font-size: 14px;
            margin   : 2px 0px;
            display  : flex;

            div {
                vertical-align: middle;
                padding       : 5px 5px;
            }

            .keywordInput {
                width        : 400px;
                border-radius: 5px;
                padding      : 5px;
                font-size    : 15px;
            }

            button {
                padding      : 5px 10px;
                margin       : 0px 5px;
                border-radius: 5px;
                background   : #d4d3d3;
            }

            button:hover {
                background: #b1b0b0;
            }

        }

        .keyWordList {
            .keywordBtn {
                border       : 1px solid;
                padding      : 5px 10px;
                margin       : 0px 2px;
                border-radius: 5px;

                &.changed {
                    color       : #fff;
                    background  : purple;
                    border-color: #fff;
                }
            }
        }

        .selfKeyWord {
            border       : 1px solid;
            border-radius: 5px;
            padding      : 5px 10px;
            margin       : 0px 2px;

            &.changed {
                color       : #fff;
                background  : purple;
                border-color: #fff;
            }
        }

        .description {
            width  : 99%;
            padding: 0px 3px;
            margin : 1px 0px;
            resize : none;
        }

        .title {
            width        : 95%;
            border-radius: 5px;
            padding      : 5px;
            font-size    : 15px;
            margin-left  : 2px;
        }

    }
}