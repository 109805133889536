.share-detail {
  display: flex;
  flex: 1;
  height: 70vh;
  flex-direction: column;
  align-content: center;
  margin: 30px 20px;
}
.share-detail .banner-title {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 15px;
}
.share-detail .row {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}
.share-detail .row .title {
  font-weight: bold;
  width: 100px;
}
.share-detail .row .content {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.share-detail .row .content .uploaded-image {
  width: 400px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border: 1px solid gray;
  border-radius: 5px;
}
.share-detail .row.buttons {
  justify-content: center;
  padding-top: 10px;
}
.share-detail .row.buttons button {
  background: #515bd4;
  padding: 5px 10px;
  margin: 0 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.share-detail .row.buttons button:hover {
  background: #444dad;
}
