.TeacherAbilityPage {
  margin: 5px 10px;
}
.TeacherAbilityPage .title {
  color: #515bd4;
  font-size: 30px;
}
.TeacherAbilityPage .TeacherAbilityPage_control-area {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
}
.TeacherAbilityPage .TeacherAbilityPage_control-area button {
  margin: 0 5px;
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}
.TeacherAbilityPage .TeacherAbilityPage_content-area {
  max-height: 80vh;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.TeacherAbilityPage .TeacherAbilityPage_content-area .TeacherAbilityPage__Pointers {
  display: flex;
  flex-direction: column;
  border: 2px solid #40619d;
  border-radius: 5px;
  margin-bottom: 10px;
}
.TeacherAbilityPage .TeacherAbilityPage_content-area .TeacherAbilityPage__Pointers .TeacherAbilityPage__Class {
  color: #ffffff;
  background-color: #40619d;
  padding: 5px 10px;
  font-size: 20px;
}
.TeacherAbilityPage .TeacherAbilityPage_content-area .TeacherAbilityPage__Pointers .TeacherAbilityPage__ClassContent {
  padding: 5px;
}
.TeacherAbilityPage .TeacherAbilityPage_content-area .TeacherAbilityPage__Pointers .TeacherAbilityPage__ClassContent .TeacherAbilityPage__ClassDescription {
  padding: 0 5px 10px 5px;
}
.TeacherAbilityPage .TeacherAbilityPage_content-area .TeacherAbilityPage__Pointers .TeacherAbilityPage__ClassContent .TeacherAbilityPage__PointerItem {
  border-radius: 5px;
  margin-bottom: 5px;
}
.TeacherAbilityPage .TeacherAbilityPage_content-area .TeacherAbilityPage__Pointers .TeacherAbilityPage__ClassContent .TeacherAbilityPage__PointerItem .TeacherAbilityPage__PointerItem_title {
  color: #ffffff;
  background-color: #40619d;
  padding: 5px 10px;
  font-size: 20px;
}
.TeacherAbilityPage .TeacherAbilityPage_content-area .TeacherAbilityPage__Pointers .TeacherAbilityPage__ClassContent .TeacherAbilityPage__PointerItem .TeacherAbilityPage__PointerItem_title.empty {
  background-color: #db4b38;
}
.TeacherAbilityPage .TeacherAbilityPage_content-area .TeacherAbilityPage__Pointers .TeacherAbilityPage__ClassContent .TeacherAbilityPage__PointerItem .TeacherAbilityPage__PointerItem_content {
  display: flex;
}
.TeacherAbilityPage .TeacherAbilityPage_content-area .TeacherAbilityPage__Pointers .TeacherAbilityPage__ClassContent .TeacherAbilityPage__PointerItem .TeacherAbilityPage__PointerItem_content label {
  display: flex;
  flex: 1;
}
.TeacherAbilityPage .TeacherAbilityPage_content-area .TeacherAbilityPage__Pointers .TeacherAbilityPage__ClassContent .TeacherAbilityPage__PointerItem .TeacherAbilityPage__PointerItem_content label .TeacherAbilityPage__PointerItem_Option {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 5px 5px 10px 5px;
  background-color: #e0eff5;
  border: 1px solid #ffffff;
}
.TeacherAbilityPage .TeacherAbilityPage_content-area .TeacherAbilityPage__Pointers .TeacherAbilityPage__ClassContent .TeacherAbilityPage__PointerItem .TeacherAbilityPage__PointerItem_content label .TeacherAbilityPage__PointerItem_Option .TeacherAbilityPage__PointerItem_Option_Title {
  padding-bottom: 15px;
}
