.home {
	.index {
		display: flex;
		padding: 40px 0;

		.shortcutItems {
			padding: 0 50px;
			width  : 300px;

			.item {
				font-weight: bold;
				font-size  : 20px;
				padding    : 10px 0;

				a{
					text-decoration:none;
				}
			}
		}

		.trainingCourses {
			width: 95%;

			.title {
				font-size  : 20px;
				font-weight: bold;
				padding    : 10px 50px;
			}

			.latestCourses {
				width: 100%;

				thead {
					background: #515bd4;
					color     : #ffffff;
				}

				td {
					padding: 10px;
				}

				tbody {
					.loading {
						text-align: center;
					}

					tr:hover {
						background: #e2e0e0;
					}

					td {
						border-bottom: 1px solid #515bd4;

						svg {
							color: #000000;
						}
					}
				}
			}
		}
	}
}