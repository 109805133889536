.Resource {
	.Resource__title {
		color        : #232ca0;
		font-size    : 30px;
		font-weight  : bold;
		margin-bottom: 18px;
	}

	.controlArea {
		flex-direction : row-reverse;
		justify-content: center;
		padding        : 30px 0;

		.saveBtn {
			background   : #515bd4;
			padding      : 5px 10px;
			border       : 0;
			font-size    : 15px;
			border-radius: 5px;
			color        : #ffffff;
			cursor       : pointer;
		}
	}

	.articleDescription {
		height: 200px;
	}

	select {
		padding      : 3px;
		border-radius: 5px;
		font-size    : 15px;
		width        : 70%;
		border       : 2px solid black;
	}

	button {
		background   : #515bd4;
		padding      : 5px 10px;
		border       : 0;
		font-size    : 15px;
		border-radius: 5px;
		color        : #ffffff;
		cursor       : pointer;
		margin       : 0 5px;
	}

	.classDisplayArea {
		display: flex;
		.classItem {
			display: flex;
			background   : #515bd4;
			padding      : 5px 10px;
			border       : 0;
			font-size    : 15px;
			border-radius: 5px;
			color        : #ffffff;
			margin       : 0 5px;

			svg{
				margin-left: 5px;
				cursor       : pointer;
			}
		}
	}
}