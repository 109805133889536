.contactusContent {
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0 auto;
}
.contactusContent .contactusContent_title {
  text-align: center;
  font-weight: bold;
  font-size: 32px;
  padding: 30px 0 ;
}
.contactusContent .contactusContent_desc {
  text-align: center;
  font-weight: bold;
}
.contactusContent .contactusContent_conditions {
  display: flex;
  justify-content: center;
  padding: 50px;
}
.contactusContent .contactusContent_conditions .contactusContent_conditions_item {
  padding: 50px 30px;
  text-align: center;
}
.contactusContent .contactusContent_conditions .contactusContent_conditions_item svg {
  color: #7f1083;
  font-size: 150px;
  margin: 20px;
}
