.LessonPlanResources {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 90vh;
}
.LessonPlanResources .LessonPlanResources__searchCondition .LessonPlanResources__searchCondition__title {
  width: 150px;
  text-align: center;
}
.LessonPlanResources .LessonPlanResources__searchCondition .academicYears select,
.LessonPlanResources .LessonPlanResources__searchCondition .lessonPlanGrades select,
.LessonPlanResources .LessonPlanResources__searchCondition .lessonTargetReigons select,
.LessonPlanResources .LessonPlanResources__searchCondition .lessonTargetKernels select,
.LessonPlanResources .LessonPlanResources__searchCondition .lessonTargetPoints select,
.LessonPlanResources .LessonPlanResources__searchCondition .lessonTargetContents select,
.LessonPlanResources .LessonPlanResources__searchCondition .lessonTargetActivities select {
  font-size: 14px;
  border-radius: 5px;
  padding: 3px;
}
.LessonPlanResources .LessonPlanResources__searchCondition .selectedBtn {
  font-size: 16px;
  color: #ffffff;
  padding: 5px 10px;
  border: 0px;
  background: #38b3bf;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 10px;
}
.LessonPlanResources .LessonPlanResources__searchCondition .selectedBtn:hover {
  background: #32a0aa;
}
.LessonPlanResources .LessonPlanResources__searchCondition .selectedItem {
  font-size: 16px;
  color: #ffffff;
  padding: 5px 20px;
  border: 0px;
  background: #007db4;
  cursor: pointer;
  border-radius: 5px;
  margin: 5px 3px;
}
.LessonPlanResources .LessonPlanResources__searchCondition .selectedItem:hover {
  background: #016a97;
}
.LessonPlanResources .LessonPlanResources__searchCondition .LessonPlanResources__searchCondition__controlArea button {
  background: #871e8c;
  font-size: 16px;
  color: #ffffff;
  padding: 5px 20px;
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  margin: 5px auto;
}
.LessonPlanResources .LessonPlanResources__searchCondition .LessonPlanResources__searchCondition__controlArea button:hover {
  background: #651769;
}
.LessonPlanResources .LessonPlanResources__result {
  height: 100%;
  overflow: auto;
}
.LessonPlanResources .LessonPlanResources__result table {
  width: 100%;
}
.LessonPlanResources .LessonPlanResources__result table thead {
  background: #515bd4;
  color: #ffffff;
}
.LessonPlanResources .LessonPlanResources__result table td {
  padding: 10px;
}
.LessonPlanResources .LessonPlanResources__result table tbody .loading {
  text-align: center;
}
.LessonPlanResources .LessonPlanResources__result table tbody tr:hover {
  background: #e2e0e0;
}
.LessonPlanResources .LessonPlanResources__result table tbody td {
  border-bottom: 1px solid #515bd4;
}
.LessonPlanResources .LessonPlanResources__result table tbody td svg {
  color: #000000;
}
.LessonPlanResources .LessonPlanResources__result table tbody td .LessonPlanResources__result__item__readBtn {
  padding: 5px 15px;
  color: #383d41;
  background: #e2e3e5;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
  margin: 0 5px;
}
.LessonPlanResources .LessonPlanResources__result table tbody td .LessonPlanResources__result__item__readBtn:hover {
  background: #383d41;
  color: #e2e3e5;
}
.home .LessonPlanResources {
  margin: 30px 20px;
}
.home .LessonPlanResources .title {
  font-size: 32px;
  margin: 32px 0;
}
.home .LessonPlanResources .warn_word {
  color: red;
}
