.CourseType .CourseType__title {
  font-size: 20px;
  font-weight: bold;
  padding: 30px 10px;
}
.CourseType .CourseType__content table {
  margin: 0 auto;
  width: 80%;
}
.CourseType .CourseType__content table thead {
  background: #515bd4;
  color: #ffffff;
}
.CourseType .CourseType__content table td {
  padding: 10px;
}
.CourseType .CourseType__content table tbody .loading {
  text-align: center;
}
.CourseType .CourseType__content table tbody tr:hover {
  background: #e2e0e0;
}
.CourseType .CourseType__content table tbody td {
  border-bottom: 1px solid #515bd4;
}
.CourseType .CourseType__content table tbody td svg {
  color: #000000;
}
