.PointerManagement {
    .PointerManagement__controlArea {
        display: flex;
        padding: 5px 0;

        button {
            margin       : 0 5px;
            background   : #515bd4;
            padding      : 5px 10px;
            border       : 0;
            border-radius: 5px;
            color        : #ffffff;
            cursor       : pointer;
        }
    }

    .PointerManagement__content {
        .PointerManagement__header {
            display: flex;

            div {
                flex      : 1;
                color     : #ffffff;
                background: #515bd4;
                padding   : 10px;
            }

            div:last-child {
                width: 140px;
            }
        }

        .PointerManagement__items {

            :hover{
                background: #e2e0e0;
            }

            
        }
    }

    table {
        width: 100%;

        thead {
            background: #515bd4;
            color     : #ffffff;
        }

        td {
            padding: 10px;
        }

        tbody {
            .loading {
                text-align: center;
            }

            tr:hover {
                background: #e2e0e0;
            }

            td {
                border-bottom: 1px solid #515bd4;

                svg {
                    color: #000000;
                }

                .PointerManagement__content__deleteBtn {
                    padding      : 5px 15px;
                    color        : #721c24;
                    background   : #f8d7da;
                    border-radius: 5px;
                    border       : 0px;
                    cursor       : pointer;
                    margin       : 0 5px;
                }

                .PointerManagement__content__deleteBtn:hover {
                    background: #721c24;
                    color     : #f8d7da;
                }

                .PointerManagement__content__editBtn {
                    padding      : 5px 15px;
                    color        : #383d41;
                    background   : #e2e3e5;
                    border-radius: 5px;
                    border       : 0px;
                    cursor       : pointer;
                    margin       : 0 5px;
                }

                .PointerManagement__content__editBtn:hover {
                    background: #383d41;
                    color     : #e2e3e5;
                }

                .PointerManagement_item {
                    width             : 200px;
                    max-height        : 300px;
                    white-space       : nowrap;
                    overflow          : auto;
                    -ms-overflow-style: none;
                    /* IE and Edge */
                    scrollbar-width   : none;
                    /* Firefox */
                }

                .PointerManagement_item::-webkit-scrollbar {
                    display: none;
                }
            }

            td:last-child {
                width: 140px;
            }

            td:nth-last-child(2) {
                width: 130px;
            }
        }
    }

}