.Index {
    display: flex;
    flex: 1;
    height: 90vh;
    flex-direction: column;
    align-content: center;
    margin: 30px 20px;

    a {
        color: #0000EE;
        cursor: pointer;
    }

    .upcoming-course {
        .content {
            height: 300px;
            width: 100%;

            .MuiDataGrid-columnsContainer {
                color: #7f1083;
                font-size: 15px;
                font-weight: bold;
            }
        }
    }

    .certifications {
        display: flex;
        flex-direction: column;
        .level-item{
            display: flex;
        }
        // .content {
        //     height: 250px;
        //     width: 100%;

        //     .MuiDataGrid-columnsContainer {
        //         color: #7f1083;
        //         font-size: 15px;
        //         font-weight: bold;
        //     }
        // }
    }

    .joined-courses {
        .content {
            height: 300px;
            width: 100%;

            .MuiDataGrid-columnsContainer {
                color: #7f1083;
                font-size: 15px;
                font-weight: bold;
            }
        }
    }

    .titles{
        display: flex;
        
        .title {
            font-size: 32px;
            padding: 10px;
            cursor: pointer;
            background: #e0e0e0;
            color: #858585;
    
            &.selected {
                border-top: 1px solid #e0e0e0;
                border-left: 1px solid #e0e0e0;
                border-right: 1px solid #e0e0e0;
                background: #ffffff;
                color: #000000;
            }
        }
    }
    
}