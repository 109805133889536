.management .SignUp {
  flex: 1;
}
.SignUp .memberRegisterContent {
  width: 90%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 auto;
}
.SignUp .memberRegisterContent .memberRegisterTitle {
  font-size: 40px;
  text-align: center;
}
.SignUp .memberRegisterContent .tableBasic {
  width: 100%;
  border-radius: 5px;
  border-collapse: collapse;
}
.SignUp .memberRegisterContent .tableBasic .title {
  text-align: right;
  width: 150px;
}
.SignUp .memberRegisterContent .tableBasic td {
  height: 5px;
  padding: 4px;
}
.SignUp .memberRegisterContent .tableBasic td .divGender {
  display: inline-block;
  padding: 0 10px;
}
.SignUp .memberRegisterContent .tableBasic td .divGender label {
  cursor: pointer;
}
.SignUp .memberRegisterContent .tableBasic td .divGender input {
  width: auto;
}
.SignUp .memberRegisterContent .tableBasic td select {
  width: 100%;
}
.SignUp .memberRegisterContent .tableBasic td .signupinput {
  width: 96%;
  border-radius: 5px;
  padding: 5px;
  font-size: 15px;
}
.SignUp .memberRegisterContent .tableBasic td .socialArea {
  display: flex;
}
.SignUp .memberRegisterContent .tableBasic td .socialArea .socialType {
  width: 150px;
}
.SignUp .memberRegisterContent .tableBasic td .socialArea .socialNumber {
  flex: 1;
}
.SignUp .memberRegisterContent .memberRegisterRow {
  margin: 10px 0;
  font-weight: bold;
  padding: 5px 0;
  font-size: 20px;
}
.SignUp .memberRegisterContent .memberRegisterRow svg {
  margin: 0 10px;
  color: #7f1083;
}
.SignUp .memberRegisterContent .submitBtn {
  background: #534bc5;
  color: #ffffff;
  font-size: 20px;
  padding: 10px 40px;
  border-radius: 5px;
  text-align: center;
  margin: 10px auto;
  cursor: pointer;
}
.SignUp .errorMsg {
  background: #ff0000;
  height: 20px;
  color: #ffffff;
  font-size: 15px;
  padding: 2px 4px;
}
.SignUp .passwordCheck {
  height: 30px;
  color: #ff0000;
  font-size: 15px;
}
