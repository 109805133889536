.ClassPage .title {
  color: #515bd4;
  font-size: 25px;
}
.ClassPage .ClassPage__controlArea {
  display: flex;
  padding: 5px 0;
}
.ClassPage button {
  margin: 0 5px;
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.ClassPage .tabs {
  display: flex;
  font-size: 20px;
}
.ClassPage .tabs .langTab {
  color: #ffffff;
  border-radius: 5px 5px 0 0;
  background: #515bd4;
  padding: 3px 30px;
  cursor: pointer;
}
.ClassPage .tabs .inactive {
  color: #797878;
  background: #b5b8df;
}
.ClassPage table {
  width: 100%;
}
.ClassPage table thead {
  background: #515bd4;
  color: #ffffff;
}
.ClassPage table td {
  padding: 10px;
}
.ClassPage table tbody .loading {
  text-align: center;
}
.ClassPage table tbody tr:hover {
  background: #e2e0e0;
}
.ClassPage table tbody td:first-child {
  width: 100px;
}
.ClassPage table tbody td {
  border-bottom: 1px solid #515bd4;
}
.ClassPage table tbody td svg {
  color: #000000;
}
.ClassPage table tbody td .ClassPage__content__descripton {
  height: 200px;
}
.ClassPage table tbody td textarea {
  font-size: 14px;
  width: 90%;
  border-radius: 5px;
  padding: 3px;
  height: 50px;
}
