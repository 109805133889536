.MemberCertificationsManage{
	.MemberCertificationsManage__searchArea{
		padding: 5px 0;
		input {
			padding: 3px;
			border-radius: 5px;
			font-size: 15px;
			margin: 0 10px;
			width: 400px;
		}

		button{
			background: #515bd4;
			padding: 5px 10px;
			border: 0;
			border-radius: 5px;
			color: #ffffff;
			cursor: pointer;
			margin-right: 5px;
		}
	}

    .MemberCertificationsManage__content {        
		height: 75vh;
		overflow: auto;    
				
		.MuiDataGrid-columnsContainer {
            background: #515bd4;
            color     : #ffffff;
        }

        .MuiDataGrid-cell {
           
			.approveBtn{
				background: #337ab7;
				padding: 5px 10px;
				border: 0;
				border-radius: 5px;
				color: #ffffff;
				margin: 0 5px;
				cursor: pointer;
			}

			.deatilBtn{
				background: #155724;
				padding: 5px 10px;
				border: 0;
				border-radius: 5px;
				color: #ffffff;
				margin-left: 5px;
				cursor: pointer;

				span{
					background: #ffffff;
					color: #155724;
					border-radius: 50%;
					padding: 2px 5px;
					margin-right: 10px;
				}
			}
        }


    	table{
    		width: 100%;
    		thead{
    			background: #515bd4;
    			color: #ffffff;		
    		}

    		td{
    			padding: 10px;
    		}

    		tbody{
    			.loading{
    				text-align: center;
    			}		

    			tr:hover{
	    			background: #e2e0e0;	    			
	    		}

		    	td{	
		    		border-bottom: 1px solid #515bd4;

		    		.MemberManagement__content__activeBtn--stop{
		    			padding: 3px 15px;
						color: #ffffff;
						background: #a94442; 
						border-radius: 5px;
						border: 0px;
						cursor: pointer;
		    		}
		    		.MemberManagement__content__activeBtn--stop:hover{	    			
						background: #823433;	    		
					}

					.MemberManagement__content__validationBtn{
						padding: 3px 15px;
						color: #ffffff;
						background: #133ede; 
						border-radius: 5px;
						border: 0px;
						cursor: pointer;
						margin: 0 5px;
					}

					.MemberManagement__content__validationBtn:hover{	    			
						background: #07238c;
					}

					.stopMember{
						background: #f8d7da;
						padding: 5px 10px;
						border: 0;
						border-radius: 5px;
						color: #721c24;
						cursor: pointer;
					}

					.activeMember{
						background: #d4edda;
						padding: 5px 10px;
						border: 0;
						border-radius: 5px;
						color: #155724;
						cursor: pointer;
					}

					.approveBtn{
						background: #337ab7;
						padding: 5px 10px;
						border: 0;
						border-radius: 5px;
						color: #ffffff;
						margin-left: 5px;
						cursor: pointer;
					}
		    	}
    		}    		
    	}
    }
}
