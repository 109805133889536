.planslist {
  flex: 1;
  height: 90vh;
  margin: 30px 20px;
}
.planslist .plansBanner .listHeader {
  font-size: 32px;
  color: #000000;
}
.planslist .plansBanner .newPlanBtn {
  font-size: 16px;
  color: #ffffff;
  padding: 10px 20px;
  border: 0px;
  background: #029b5a;
  cursor: pointer;
  border-radius: 5px;
}
.planslist .plansBanner .newPlanBtn:hover {
  background: #025c37;
}
.planslist .planslist__content {
  height: 100%;
  overflow: auto;
}
.planslist .planslist__content table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
  margin-top: 10px;
}
.planslist .planslist__content table thead th {
  padding: 8px 16px;
}
.planslist .planslist__content table tbody tr:hover {
  background: #ddd;
}
.planslist .planslist__content table tbody td {
  text-align: center;
  padding: 5px;
}
.planslist .planslist__content table tbody td .keywordArea {
  display: flex;
}
.planslist .planslist__content table tbody td .keywordArea .count {
  padding: 0 5px;
  margin-right: 5px;
  border-radius: 5px;
  color: #ffffff;
  background: #029b5a;
}
.planslist .planslist__content table tbody td .keywordArea .keywords {
  flex: 1;
  text-align: left;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
}
.planslist .planslist__content table tbody td .keywordArea .keywords div {
  display: inline;
}
.planslist .planslist__content table tbody th:first-child,
.planslist .planslist__content table tbody td:first-child {
  text-align: left;
}
.planslist .planslist__content table tbody .editor {
  font-size: 16px;
  color: #ffffff;
  padding: 5px 10px;
  border: 0px;
  background: #027db4;
  cursor: pointer;
  border-radius: 5px;
  margin-right: 5px;
}
.planslist .planslist__content table tbody .editor:hover {
  background: #003249;
}
.planslist .planslist__content table tbody .deletebtn {
  font-size: 16px;
  color: #ffffff;
  padding: 5px 10px;
  border: 0px;
  background: #a30014;
  cursor: pointer;
  border-radius: 5px;
}
.planslist .planslist__content table tbody .deletebtn:hover {
  background: #75000e;
}
