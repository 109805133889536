.TeachersManagement {
	.TeachersManagement__title {
		color: #232ca0;
		font-size: 30px;
		font-weight: bold;
		margin-bottom: 18px;
	}

	.TeachersManagement__control_panel {
		align-items: center;


		svg {
			font-size: 20px;
			margin-right: 10px;
		}

		.Admin__row {
			width: 200px;
		}
	}

	.TeachersManagement__content {
		display: flex;

		.TeachersManagement__unselected_content {
			width: 200px;
			display: flex;
			flex-direction: column;
			overflow-y: auto;
			height: 550px;
			// flex-wrap: wrap;

			.teacher-item {
				border-radius: 10px;
				padding: 5px 10px;
				margin: 3px;
				border: 1px solid #838acf;
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				word-break: break-all;

				&:hover {
					background: #838acf;
				}
			}

			.selected {
				background: #2d3485;
				color: #ffffff;
			}
		}

		.TeachersManagement__selected_content {
			display: flex;
			flex-direction: column;
			flex: 1;
			height: 550px;
			overflow-y: auto;

			.teacher-item {
				border-radius: 10px;
				padding: 5px 10px;
				margin: 3px;
				border: 1px solid #616ace;
				background: #616ace;
				color: #ffffff;
				display: flex;
				flex-direction: column;

				.row {
					display: flex;
					justify-content: space-between;
					align-items: center;

					.left-area {
						display: flex;
						align-items: center;
						word-break: break-all;

						.remove-btn{
							display: flex;
							justify-content: center;
							align-items: center;
							width: 30px;
						}

						svg, i {
							font-size: 20px !important;
							margin: 5px;
							cursor: pointer;
						}
					}

					.right-area {
						display: flex;
						align-items: center;

						div {
							display: flex;
							align-items: center;

							.option {
								cursor: pointer;
								padding: 0 10px;
								color: #999dc9;

								&:hover {
									font-size: 20px;
									color: #ffffff;
								}
							}

							.selected {
								font-size: 20px;
								font-weight: bold;
								color: #92ff2c;
							}
						}
					}

					.admin-info {
						flex: 1;
						display: flex;
						align-items: center;
						width: 100%;
						background: #8b90c5;
						border-radius: 10px;
						padding: 5px 10px;
						margin: 3px;

						.confirm-btn {
							width: 50px;
							padding: 0 10px;
						}

						.admin-full-name {
							flex: 1;
							display: flex;
							align-items: center;

							.title {
								margin-left: 10px;
								width: 50px;
							}
						}

						.admin-id {
							flex: 1.5;
							display: flex;
							align-items: center;

							.title {
								margin-left: 10px;
								width: 140px;
							}
						}

						.title {
							width: 100px;
							font-weight: bold;
						}
					}

					.delete-certification {
						width: 60px;
						background: #64656d;
						padding: 5px 10px;
						border: 0;
						border-radius: 5px;
						margin-right: 15px;
						color: #ffffff;
						cursor: pointer;

						&:hover {
							background: #cbccd8;
						}
					}
				}


			}
		}

	}

	.TeachersManagement__save_area {
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.saveBtn {
			background: #515bd4;
			padding: 5px 10px;
			border: 0;
			border-radius: 5px;
			color: #ffffff;
			cursor: pointer;

			&:hover {
				background: #2d3485;
			}
		}

		.error-msg {
			color: #f82f2f
		}

		.success-msg {
			color: #19be0a
		}
	}

}