.sidebar {
  display: flex;
  flex-direction: column;
  width: 280px;
}
.sidebar .sidebarHeader {
  height: 129px;
  background-image: url(../../source/sidebar_header.png);
  background-repeat: no-repeat;
  background-size: contain;
}
.sidebar .sideLinkBox {
  display: flex;
  flex-direction: column;
}
.sidebar .sideLinkBox .sidebarLink {
  padding: 10px 0;
  margin: 2px 0;
  background-color: #7f1083;
  border-radius: 5px;
  font-size: 17px;
  color: #FFFFFF;
  text-align: center;
  text-decoration: none;
}
.sidebar .sideLinkBox .sidebarLink:hover {
  background: #68056B;
}
