.MemberInfo .Admin__row .Admin__row__item i {
  font-weight: bold;
}
.MemberInfo .Admin__row button {
  margin: 0 auto;
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.MemberInfo .Admin__row.update-success {
  color: #198754;
}
.MemberInfo .Admin__row.flex-center {
  justify-content: center;
}
