.ClassPage {
    .title {
        color    : #515bd4;
        font-size: 25px;
    }

    .ClassPage__controlArea {
        display: flex;
        padding: 5px 0;
    }

    button {
        margin       : 0 5px;
        background   : #515bd4;
        padding      : 5px 10px;
        border       : 0;
        border-radius: 5px;
        color        : #ffffff;
        cursor       : pointer;
    }

    .tabs {
        display  : flex;
        font-size: 20px;

        .langTab {
            color        : #ffffff;
            border-radius: 5px 5px 0 0;
            background   : #515bd4;
            padding      : 3px 30px;
            cursor       : pointer;
        }

        .inactive {
            color     : #797878;
            background: #b5b8df;
        }
    }

    table {
        width: 100%;

        thead {
            background: #515bd4;
            color     : #ffffff;
        }

        td {
            padding: 10px;
        }

        tbody {
            .loading {
                text-align: center;
            }

            tr:hover {
                background: #e2e0e0;
            }

            td:first-child {
                width: 100px;
            }

            td {
                border-bottom: 1px solid #515bd4;

                svg {
                    color: #000000;
                }

                .ClassPage__content__descripton{
                    height: 200px;
                }

                textarea {
                    font-size    : 14px;
                    width        : 90%;
                    border-radius: 5px;
                    padding      : 3px;
                    height       : 50px;
                }
            }
        }
    }

}