.contactusContent{
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin: 0 auto;

    .contactusContent_title{
        text-align: center;
        font-weight: bold;
        font-size: 32px;
        padding: 30px 0 ;
    }

    .contactusContent_desc{
        text-align: center;
        font-weight: bold;
    }

    .contactusContent_conditions{
        display: flex;
        justify-content: center;        
        padding: 50px;
        .contactusContent_conditions_item{
            padding: 50px 30px;
            text-align: center;
            svg{
                color: #7f1083;
                font-size: 150px;
                margin: 20px;
            }
        }
    }
}