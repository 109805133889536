.Home button {
  background: #515bd4;
  padding: 5px 10px;
  margin: 0 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.Home .Home_Image_controll {
  display: flex;
}
.Home .Home_Image_controll svg {
  font-size: 20px;
  cursor: pointer;
  margin: 0 3px;
}
.Home .Home_Image_controll .uploadInput {
  display: none;
}
.Home .Home__content__image {
  margin: 5px 0;
  width: 300px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border: 1px solid gray;
  border-radius: 5px;
}
