.Courses .Courses__controlArea {
  display: flex;
  padding: 5px 0;
}
.Courses .Courses__controlArea svg {
  margin: 0 15px;
}
.Courses .Courses__controlArea select {
  padding: 3px;
  border-radius: 5px;
  font-size: 15px;
  margin: 0 10px;
  width: 400px;
}
.Courses .Courses__controlArea button {
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.Courses .Courses__content {
  height: 75vh;
  overflow: auto;
}
.Courses .Courses__content table {
  width: 100%;
}
.Courses .Courses__content table thead {
  background: #515bd4;
  color: #ffffff;
}
.Courses .Courses__content table td {
  padding: 10px;
}
.Courses .Courses__content table tbody .loading {
  text-align: center;
}
.Courses .Courses__content table tbody tr:hover {
  background: #e2e0e0;
}
.Courses .Courses__content table tbody td {
  border-bottom: 1px solid #515bd4;
}
.Courses .Courses__content table tbody td svg {
  color: #000000;
}
.Courses .Courses__content table tbody td .Courses__content__deleteBtn {
  padding: 5px 15px;
  color: #721c24;
  background: #f8d7da;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
  margin: 0 5px;
}
.Courses .Courses__content table tbody td .Courses__content__deleteBtn:hover {
  background: #721c24;
  color: #f8d7da;
}
.Courses .Courses__content table tbody td .Courses__content__editBtn {
  padding: 5px 15px;
  color: #383d41;
  background: #e2e3e5;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
  margin: 0 5px;
}
.Courses .Courses__content table tbody td .Courses__content__editBtn:hover {
  background: #383d41;
  color: #e2e3e5;
}
.Courses .Courses__content table tbody td:nth-last-child(2) {
  width: 100px;
}
.Courses .Courses__content table tbody td:last-child {
  width: 150px;
}
