.management {
    .MemberProfile {
        flex: 1;
    }
}

.MemberProfile {
    .memberRegisterContent {
        width         : 90%;
        display       : flex;
        flex-direction: column;
        padding       : 20px;
        margin        : 0 auto;

        .memberRegisterTitle {
            font-size : 40px;
            text-align: center;
        }

        .tableBasic {
            width          : 100%;
            border-radius  : 5px;
            border-collapse: collapse;

            .title {
                text-align: right;
                width     : 150px;
            }

            td {
                height : 5px;
                padding: 4px;

                .divGender {
                    display: inline-block;
                    padding: 0 10px;

                    label {
                        cursor: pointer;
                    }

                    input {
                        width: auto;
                    }
                }

                .recieve-notification-item {
                    cursor: pointer;

                    input {
                        width: auto;
                    }
                }

                select {
                    width: 100%;
                }

                .signupinput {
                    width        : 96%;
                    border-radius: 5px;
                    padding      : 5px;
                    font-size    : 15px;
                }

                .socialArea {
                    display: flex;

                    .socialType {
                        width: 150px;
                    }

                    .socialNumber {
                        flex: 1;
                    }
                }

                .email-validation-input {
                    width: 100px;
                }

                button {
                    margin       : 0 5px;
                    background   : #515bd4;
                    padding      : 5px 10px;
                    border       : 0;
                    border-radius: 5px;
                    color        : #ffffff;
                    cursor       : pointer;
                }

                .certificationArea {
                    display: flex;
                    
                    svg{
                        margin: 0 5px;
                        cursor:pointer;
                    }

                    .upload_file_btn {
                        margin       : 0 5px;
                        background   : #515bd4;
                        padding      : 2px 10px;
                        border       : 0;
                        border-radius: 5px;
                        color        : #ffffff;
                        cursor       : pointer;
                    }

                    .file {
                        display: none;
                    }
                }

            }


        }

        .memberRegisterRow {
            margin     : 10px 0;
            font-weight: bold;
            padding    : 5px 0;
            font-size  : 20px;

            svg {
                margin: 0 10px;
                color : #7f1083;
            }
        }

        .submitBtn {
            background   : #534bc5;
            color        : #ffffff;
            font-size    : 20px;
            padding      : 10px 40px;
            border-radius: 5px;
            text-align   : center;
            margin       : 10px auto;
            cursor       : pointer;
        }


    }

    .errorMsg {
        background: #ff0000;
        height    : 20px;
        color     : #ffffff;
        font-size : 15px;
        padding   : 2px 4px;
    }

    .passwordCheck {
        height   : 30px;
        color    : #ff0000;
        font-size: 15px;
    }
}