.shareContent {
  display: flex;
  flex: 1;
  height: 90vh;
  flex-direction: column;
  align-content: center;
  margin: 30px 20px;
}
.shareContent .shareContent_title {
  font-size: 32px;
  margin: 32px 0;
}
.shareContent .shareContent__controlArea {
  display: flex;
}
.shareContent .shareContent__controlArea .uploadLabel {
  padding: 5px 10px;
  border-radius: 5px;
  background: #00a7f0;
  margin: 0 5px;
  cursor: pointer;
  color: #ffffff;
  font-size: 15px;
}
.shareContent .shareContent__controlArea .uploadLabel input {
  display: none;
}
.shareContent .shareContent__controlArea button {
  padding: 5px 10px;
  font-size: 15px;
  border-radius: 5px;
  border: 0px;
  margin: 0 5px;
  background: #00a7f0;
  cursor: pointer;
  color: #ffffff;
}
.shareContent .shareContent__controlArea button:hover {
  background: #0198d8;
}
.shareContent .shareContent_section {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.shareContent .shareContent_section.hide {
  display: none;
}
.shareContent .shareContent_section .title {
  padding-top: 20px;
  font-weight: 800px;
  font-size: 25px;
}
.shareContent .shareContent_section .MuiDataGrid-columnsContainer {
  background: #515bd4;
  color: #ffffff;
}
.shareContent .shareContent_section .content {
  flex: 1;
}
.shareContent .shareContent_section .content button {
  margin-right: 5px;
}
.shareContent .shareContent_section .content .delete-btn {
  background: #dc3545;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.shareContent .shareContent_section .content .delete-btn:hover {
  background: #c82333;
}
.shareContent .shareContent_section .content .update-btn {
  background: #007bff;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.shareContent .shareContent_section .content .update-btn:hover {
  background: #0069d9;
}
.shareContent .shareContent_section .content .info-btn {
  background: #17a2b8;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.shareContent .shareContent_section .content .info-btn:hover {
  background: #138496;
}
.shareContent .shareContent_section .content .warn-btn {
  background: #ffc107;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.shareContent .shareContent_section .content .warn-btn:hover {
  background: #e0a800;
}
.shareContent .shareContent_section .content .cancel-btn {
  background: #6c757d;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.shareContent .shareContent_section .content .cancel-btn:hover {
  background: #5a6268;
}
.shareContent .shareContent_section .content .success-btn {
  background: #28a745;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.shareContent .shareContent_section .content .success-btn:hover {
  background: #218838;
}
.shareContent .shareContent_section .content .control-area {
  display: flex;
  align-items: center;
}
.shareContent .shareContent_section .content .control-area .reject-block {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dotted #17a2b8;
  border-radius: 50%;
  background: #ffffff;
  width: 30px;
  height: 30px;
  color: #17a2b8;
}
