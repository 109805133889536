.Dashboard {
  display: flex;
}
.Dashboard .menus {
  width: 300px;
}
.Dashboard .menus .logo {
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.Dashboard .menus .menu {
  background: #7f1083;
  color: #ffffff;
  padding: 10px 20px;
  margin: 4px 0;
  border-radius: 5px;
  cursor: pointer;
}
.Dashboard .menus .submenus {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.Dashboard .menus .submenu {
  background: #a503aa;
  color: #ffffff;
  padding: 5px 20px;
  margin: 4px 0;
  border-radius: 5px;
  width: 220px;
  cursor: pointer;
}
.Dashboard .menus .active {
  background: #4E0051;
}
.Dashboard .menus .menu:hover,
.Dashboard .menus .submenu:hover {
  background: #68056B;
}
.Dashboard .content {
  flex: 1;
  padding: 40px 20px 0 20px;
}
.Dashboard .content .title {
  color: #232ca0;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 18px;
}
