.successDiv{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 80%;

    h1{
        align-self: center;
    }
    
    .content{
        width: 80%;
        margin: 10px 80px;
    }
    
    .contact{
        width: 80%;
        margin: 10px 80px;
        border: 1px solid;

        p{
            margin: 10px 10px;
        }
    }
}