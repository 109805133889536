.Certificate .Certificate__title {
  font-size: 20px;
  font-weight: bold;
  padding: 30px 10px;
}
.Certificate .Certificate__content {
  padding: 0 50px;
}
.Certificate .Certificate__content .Certificate__content__desc {
  border: 1px solid #7f1083;
  border-radius: 5px;
  padding: 10px;
}
.Certificate .Certificate__content .Certificate__content__image {
  margin: 50px 0;
  height: 450px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
