.PointerPage .title {
  color: #515bd4;
  font-size: 25px;
}
.PointerPage .PointerPage__controlArea {
  display: flex;
  padding: 5px 0;
}
.PointerPage button {
  margin: 0 5px;
  background: #515bd4;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.PointerPage .tabs {
  display: flex;
  font-size: 20px;
}
.PointerPage .tabs .langTab {
  color: #ffffff;
  border-radius: 5px 5px 0 0;
  background: #515bd4;
  padding: 3px 30px;
  cursor: pointer;
}
.PointerPage .tabs .inactive {
  color: #797878;
  background: #b5b8df;
}
.PointerPage table {
  width: 100%;
}
.PointerPage table thead {
  background: #515bd4;
  color: #ffffff;
}
.PointerPage table td {
  padding: 10px;
}
.PointerPage table tbody .loading {
  text-align: center;
}
.PointerPage table tbody tr:hover {
  background: #e2e0e0;
}
.PointerPage table tbody td:first-child {
  width: 100px;
}
.PointerPage table tbody td {
  border-bottom: 1px solid #515bd4;
}
.PointerPage table tbody td svg {
  color: #000000;
}
.PointerPage table tbody td .PointerPage__content__deleteBtn {
  padding: 5px 15px;
  color: #721c24;
  background: #f8d7da;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
  margin: 0 5px;
}
.PointerPage table tbody td .PointerPage__content__deleteBtn:hover {
  background: #721c24;
  color: #f8d7da;
}
.PointerPage table tbody td .PointerPage__content__editBtn {
  padding: 5px 15px;
  color: #383d41;
  background: #e2e3e5;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
  margin: 0 5px;
}
.PointerPage table tbody td .PointerPage__content__editBtn:hover {
  background: #383d41;
  color: #e2e3e5;
}
.PointerPage table tbody td .selected-positions {
  display: flex;
  padding-top: 10px;
}
.PointerPage table tbody td .selected-positions .selected-position {
  display: flex;
  align-items: center;
  margin: 0 5px;
  background: #8a8fcf;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}
.PointerPage table tbody td .selected-positions .selected-position .removed-button {
  padding-left: 5px;
}
.PointerPage table tbody td select {
  font-size: 14px;
  border-radius: 5px;
  padding: 3px;
}
.PointerPage table tbody td textarea {
  font-size: 14px;
  width: 90%;
  border-radius: 5px;
  padding: 3px;
  height: 50px;
}
