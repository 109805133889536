.Courses{
    .Courses__controlArea{
        display: flex;
        padding: 5px 0;
        svg{
            margin:0 15px;
        }

		select {
			padding: 3px;
			border-radius: 5px;
			font-size: 15px;
			margin: 0 10px;
			width: 400px;
		}

		button{
			background: #515bd4;
			padding: 5px 10px;
			border: 0;
			border-radius: 5px;
			color: #ffffff;
			cursor: pointer;
		}
    }
    
    .Courses__content {        
		height: 75vh;
		overflow: auto;    		

    	table{
    		width: 100%;
    		thead{
    			background: #515bd4;
    			color: #ffffff;		
    		}

    		td{
    			padding: 10px;
    		}

    		tbody{
    			.loading{
    				text-align: center;
    			}		

    			tr:hover{
	    			background: #e2e0e0;	    			
	    		}

		    	td{	
					border-bottom: 1px solid #515bd4;
					svg{
						color:#000000;
					}

		    		.Courses__content__deleteBtn{
						padding: 5px 15px;
						color: #721c24;
						background: #f8d7da; 
						border-radius: 5px;
						border: 0px;
						cursor: pointer;
						margin: 0 5px;
					}

					.Courses__content__deleteBtn:hover{	    			
						background: #721c24; 	
						color: #f8d7da;	
					}

					.Courses__content__editBtn{
						padding: 5px 15px;
						color: #383d41;
						background: #e2e3e5; 
						border-radius: 5px;
						border: 0px;
						cursor: pointer;
						margin: 0 5px;
					}

					.Courses__content__editBtn:hover{	    			
						background: #383d41; 	
						color: #e2e3e5;	
					}
				}

				td:nth-last-child(2){
					width: 100px;
				}
				
				td:last-child{
					width: 150px;
				}
    		}    		
		}
		
    }
}