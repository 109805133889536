.UploadFile .UploadFile__title {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
}
.UploadFile .UploadFile__content .UploadFile__content__controll {
  display: flex;
  padding: 5px 5px;
}
.UploadFile .UploadFile__content .UploadFile__content__controll svg {
  font-size: 25px;
  margin: 4px;
  cursor: pointer;
}
.UploadFile .UploadFile__content .UploadFile__content__controll .uploadInput {
  display: none;
}
.UploadFile .UploadFile__content .UploadFile__content_setting {
  display: flex;
}
.UploadFile .UploadFile__content .UploadFile__content_setting .UploadFile__content_setting_preview {
  width: 300px;
  height: 300px;
  border: 1px solid black;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;
}
.UploadFile .UploadFile__content .UploadFile__content_setting .UploadFile__content_setting_preview .percentNumber {
  font-size: 50px;
}
.UploadFile .UploadFile__content .UploadFile__content_setting .UploadFile__content_setting_desc {
  flex: 1;
  height: 260px;
  border-radius: 10px;
  margin: 0 10px;
  padding: 20px;
  font-size: 18px;
}
.UploadFile .UploadFile__content .UploadFile__content__controllArea {
  text-align: center;
}
.UploadFile .UploadFile__content .UploadFile__content__controllArea .UploadFile__content__controllArea__saveBtn {
  background: #534bc5;
  color: #ffffff;
  font-size: 20px;
  padding: 5px 30px;
  border-radius: 5px;
  text-align: center;
  margin: 10px auto;
  cursor: pointer;
}
