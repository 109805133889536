.MemberSharesManage {
	.MemberCertificationsManage__searchArea {
		padding: 5px 0;

		input {
			padding: 3px;
			border-radius: 5px;
			font-size: 15px;
			margin: 0 10px;
			width: 400px;
		}

		button {
			background: #515bd4;
			padding: 5px 10px;
			border: 0;
			border-radius: 5px;
			color: #ffffff;
			cursor: pointer;
			margin-right: 5px;
		}
	}

	.MemberSharesManage__content {
		height: 75vh;
		overflow: auto;

		&.hide{
            display: none;
        }

		.control-area{
			display: flex;
			align-items: center;
			.reject-block{
				display: flex;
				align-items: center;
				justify-content: center;
				border: 2px dotted #17a2b8;
				border-radius: 50%;
				background: #ffffff;
				width: 30px;
				height: 30px;
				color: #17a2b8;
			}
		}

		.MuiDataGrid-columnsContainer {
			background: #515bd4;
			color: #ffffff;
		}

		button {
			margin-right: 5px;
		}

		.delete-btn {
			background: #dc3545;
			padding: 5px 10px;
			border: 0;
			border-radius: 5px;
			color: #ffffff;
			cursor: pointer;

			&:hover {
				background: #c82333;
			}
		}

		.update-btn {
			background: #007bff;
			padding: 5px 10px;
			border: 0;
			border-radius: 5px;
			color: #ffffff;
			cursor: pointer;

			&:hover {
				background: #0069d9;
			}
		}

		.info-btn {
			background: #17a2b8;
			padding: 5px 10px;
			border: 0;
			border-radius: 5px;
			color: #ffffff;
			cursor: pointer;

			&:hover {
				background: #138496;
			}
		}

		.warn-btn {
			background: #ffc107;
			padding: 5px 10px;
			border: 0;
			border-radius: 5px;
			color: #ffffff;
			cursor: pointer;

			&:hover {
				background: #e0a800;
			}
		}

		.cancel-btn {
			background: #6c757d;
			padding: 5px 10px;
			border: 0;
			border-radius: 5px;
			color: #ffffff;
			cursor: pointer;

			&:hover {
				background: #5a6268;
			}
		}

		.success-btn {
			background: #28a745;
			padding: 5px 10px;
			border: 0;
			border-radius: 5px;
			color: #ffffff;
			cursor: pointer;

			&:hover {
				background: #218838;
			}
		}
	}
}