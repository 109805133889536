.Login .content {
  margin: 30px auto;
  width: 700px;
  background: #7f1083;
  padding: 15px;
}
.Login .content .logo {
  height: 200px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.Login .content .title {
  font-size: 30px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
}
.Login .content .form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.Login .content .form .row {
  display: flex;
  padding: 3px 0;
}
.Login .content .loginform {
  color: #ffffff;
}
.Login .content .loginform .label {
  font-size: 20px;
  margin: 0 10px;
}
.Login .content .loginform .textInput {
  width: 250px;
  border-radius: 5px;
  padding: 5px;
  font-size: 15px;
}
.Login .content .loginform .loginBtn {
  border-radius: 5px;
  background: #ffffff;
  color: #7f1083;
  padding: 5px 20px;
  margin: 10px 0;
  cursor: pointer;
  font-weight: bold;
  height: 30px;
}
.Login .content .loginform .loginBtn:hover {
  background: lightgray;
}
.Login .content .btn {
  border-radius: 5px;
}
.Login .content .errorMsg {
  height: 50px;
  color: #ff0000;
  font-size: 15px;
}
