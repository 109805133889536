.PointerManagement__item {
    display      : flex;
    border-bottom: 1px solid #515bd4;

    .PointerManagement__item__attr {
        flex              : 1;
        padding           : 10px;
        width             : 100px;
        max-height        : 300px;
        white-space       : nowrap;
        overflow          : auto;
        cursor            : pointer;
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width   : none;
        /* Firefox */
    }

    .PointerManagement__item__attr::-webkit-scrollbar {
        display: none;
    }

    div:last-child {
        width: 140px;

        .PointerManagement__content__deleteBtn {
            padding      : 5px 15px;
            color        : #721c24;
            background   : #f8d7da;
            border-radius: 5px;
            border       : 0px;
            cursor       : pointer;
            margin       : 0 5px;
        }

        .PointerManagement__content__deleteBtn:hover {
            background: #721c24;
            color     : #f8d7da;
        }

        .PointerManagement__content__editBtn {
            padding      : 5px 15px;
            color        : #383d41;
            background   : #e2e3e5;
            border-radius: 5px;
            border       : 0px;
            cursor       : pointer;
            margin       : 0 5px;
        }

        .PointerManagement__content__editBtn:hover {
            background: #383d41;
            color     : #e2e3e5;
        }

        .PointerManagement_item {
            width             : 200px;
            max-height        : 300px;
            white-space       : nowrap;
            overflow          : auto;
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width   : none;
            /* Firefox */
        }

        .PointerManagement_item::-webkit-scrollbar {
            display: none;
        }
    }
}