.planDetailContainer {
    margin    : 0px 0 auto;
    background: #ffffff;

    .controllBtn {
        display: flex;

        button {
            border-radius: 8px 8px 0 0;
        }

        .deleteInstances {
            font-size: 1.7em;
            padding  : 5px 10px;
            cursor   : pointer;
            float    : right;
        }

        .selected {
            background: #ffffff;
            outline   : none;
        }

        .waitingApprove {
            color  : #d9534f;
            padding: 0 5px;
        }

        .controll--Btns {
            display    : flex;
            align-items: center;
            flex       : 1;

            .submitLessonPlan {
                background   : #871e8c;
                padding      : 8px 15px;
                margin       : 5px;
                border-radius: 5px;
                color        : #ffffff;
                cursor       : pointer;
            }

            .rejectHistoryArea {
                position   : relative;
                display    : flex;
                align-items: center;
                height     : 100%;

                .rejectTimes {
                    display        : flex;
                    justify-content: center;
                    align-items    : center;
                    background     : #d9534f;
                    width          : 30px;
                    height         : 30px;
                    border-radius  : 10px;
                    color          : white;

                    .rejectDetail {
                        position: relative;

                        .rejectDetailContent {
                            position  : absolute;
                            top       : 15px;
                            display   : none;
                            width     : 500px;
                            max-height: 400px;
                            overflow  : auto;

                            table {
                                color     : #000000;
                                background: #ffffff;
                                box-shadow: 0px 0px 10px 3px #8f8f8f;

                                td {
                                    padding: 3px 5px;
                                }
                            }
                        }
                    }
                }

                .rejectTimes:hover .rejectDetail .rejectDetailContent {
                    display: block;
                }

            }
        }
    }

    .stepController {
        background   : #c7d3f7b8;
        width        : 200px;
        height       : 40px;
        border-radius: 3px;
        margin       : 0px 2px;
        border-bottom: 0px;
        cursor       : pointer;
        font-size    : 20px;
        font-weight  : 700;
    }

    .planDetailContent {
        margin  : 15px 0 0 0;
        height  : 75vh;
        overflow: auto;

        .sendAll {
            margin     : 15px 0px;
            margin-left: 5px;
            width      : 99%;
            height     : 35px;
            background : #871e8c;
            color      : #ffffff;
            font-size  : 15px;
            font-weight: 400;
            cursor     : pointer;
        }
    }

}