.MemberLessonPlans {
  height: 80vh;
}
.MemberLessonPlans .MemberLessonPlans__content {
  height: 75vh;
}
.MemberLessonPlans .MemberLessonPlans__content .MuiDataGrid-columnsContainer {
  background: #515bd4;
  color: #ffffff;
}
.MemberLessonPlans .MemberLessonPlans__content .MuiDataGrid-cell .approveBtn {
  background: #337ab7;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  margin-left: 5px;
  cursor: pointer;
}
.MemberLessonPlans .MemberLessonPlans__content table {
  width: 100%;
  overflow: auto;
}
.MemberLessonPlans .MemberLessonPlans__content table thead {
  background: #515bd4;
  color: #ffffff;
}
.MemberLessonPlans .MemberLessonPlans__content table td {
  padding: 10px;
}
.MemberLessonPlans .MemberLessonPlans__content table tbody .loading {
  text-align: center;
}
.MemberLessonPlans .MemberLessonPlans__content table tbody tr:hover {
  background: #e2e0e0;
}
.MemberLessonPlans .MemberLessonPlans__content table tbody td {
  border-bottom: 1px solid #515bd4;
}
.MemberLessonPlans .MemberLessonPlans__content table tbody td .approveBtn {
  background: #337ab7;
  padding: 5px 10px;
  border: 0;
  border-radius: 5px;
  color: #ffffff;
  margin-left: 5px;
  cursor: pointer;
}
